import React from "react"
import ReactDOM from "react-dom"
import ThirdPartyStatus from "src/dashboard/components/ThirdPartyStatus"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("dashboard-third_party_status", () => {
  ReactDOM.render(
    <ThirdPartyStatus/>,
    document.getElementById("third-party-status")
  )
})
