import { Controller } from "@hotwired/stimulus"

// Enables/Disables submit button depending on if required fields have values or not
export default class extends Controller {
  static targets = ["submitButton"]

  change() {
    const allRequiredFieldsHaveValue = Array.from(
      this.element.querySelectorAll("[required]")
    ).every((field) => field.value)

    this.submitButtonTarget.disabled = !allRequiredFieldsHaveValue
  }
}
