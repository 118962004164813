import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import { IconsSet } from "../../shared/IconsSet"

export class IconsAndActionButtons extends React.PureComponent {

  static propTypes = {
    shipmentId: PropTypes.number.isRequired,
    showStyleButton: PropTypes.bool,
    showReviewButton: PropTypes.bool,
    showInspectButton: PropTypes.bool,
    showRestyleButton: PropTypes.bool,
    icons: PropTypes.array
  }

  renderActionButton(action, text) {
    return <Button
      size="sm"
      variant="outline-secondary"
      className={"action-button"}
      href={`/shipments/${this.props.shipmentId}/${action}`}>
      {text}
    </Button>
  }

  renderInspectionButton(text) {
    return <Button
      size="sm"
      variant="outline-secondary"
      className={"action-button"}
      href={`/inspection/${this.props.shipmentId}`}>
      {text}
    </Button>
  }

  render() {
    let actionButton = null
    // Render action button, actually they depend on shipment status filter, so only one could be visible
    if (this.props.showStyleButton) {
      actionButton = this.renderActionButton("styling", "Style >>")
    } else if (this.props.showReviewButton) {
      actionButton = this.renderActionButton("styling", "Review >>")
    } else if (this.props.showInspectButton) {
      actionButton = this.renderInspectionButton("Inspect >>")
    } else if (this.props.showRestyleButton) {
      actionButton = this.renderActionButton("styling", "Restyle >>")
    }

    return <div>
      {actionButton}
      <IconsSet
        icons={this.props.icons}
      />
    </div>
  }
}
