import { Controller } from "@hotwired/stimulus"

// To update list of locations suggestion based on current change
export default class extends Controller {
  static targets = ["suggestionSelect"]

  initialize () {
    this.click = this.click.bind(this)
  }

  click(event) {
    event.preventDefault()

    const { params } = event

    this.suggestionSelectTarget.value = params.id.toString()
    this.suggestionSelectTarget.dispatchEvent(new Event("change"))
  }
}
