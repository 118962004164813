import React from "react"
import PropTypes from "prop-types"
import {Modal, Button, FormGroup, Col, FormLabel, FormControl} from "react-bootstrap"
import {doPost} from "@nadinewest/common/javascript/src"
import {showErrors} from "src/shared/utils"

export class SizeModifierEditor extends React.PureComponent {

  static propTypes = {
    productId: PropTypes.number.isRequired,
    sizeModifier: PropTypes.string.isRequired,
    editAllowed: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      show: false,
      value: this.props.sizeModifier,
      savedValue: this.props.sizeModifier
    }
  }

  onModalOpen() {
    this.setState({show: true})
  }

  onModalClose() {
    this.setState({show: false, shipmentsCount: 0})
  }

  changeCallback(resp) {
    if (!resp.ok) {
      resp.json().then(json => {
        showErrors(json.errors)
      })
    } else {
      this.setState({show: false, savedValue: this.state.value})
    }
  }

  onSave() {
    this.setState({show: true, shipmentsCount: 0})
    doPost(
      `/products/${this.props.productId}/check_for_size_modifier_change`,
      {size_modifier: this.state.value},
      (resp) => {
        if (resp.ok) {
          resp.json().then(json => {
            const shipmentsCount = json.shipmentsCount
            const confirmText = shipmentsCount > 0 ?
              `Looks like this product is already styled in ${shipmentsCount} shipments. \n` +
              "If you click 'OK', shipments in to_approve and to_pack will go back in to_style. \n" +
              "All other shipments with this product will remain unchanged. Please confirm." : "Are you sure?"
            if (confirm(confirmText)) {
              doPost(
                `/products/${this.props.productId}/change_size_modifier`,
                {size_modifier: this.state.value},
                this.changeCallback.bind(this)
              )
            }
          })
        } else {
          alert("Failed to check shipments with this product")
        }
      }
    )
  }

  handleChange(event) {
    this.setState({value: event.target.value})
  }

  renderModal() {
    const options = []
    Product.SIZE_MODIFIERS.forEach(function(m) {
      options.push(
        <option key={m} value={m}>{m}</option>
      )
    })

    const btnDisabled = this.state.savedValue == this.state.value

    return (
      <Modal show={this.state.show} onHide={this.onModalClose.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Size Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="form-inline">
            <FormLabel>Size Modifier&nbsp;</FormLabel>
            <FormControl
              as="select"
              onChange={this.handleChange.bind(this)}
              value={this.state.value}>
              {options}
            </FormControl>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Col>
            <Button className="mr-1" variant="outline-secondary" onClick={this.onModalClose.bind(this)}>Cancel</Button>
            <Button variant="primary" disabled={btnDisabled} onClick={this.onSave.bind(this)}>Save</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const modal = this.state.show ? this.renderModal() : null
    const modifierLink = this.props.editAllowed ?
      <Button variant="link" onClick={this.onModalOpen.bind(this)}>
        {this.state.savedValue}
      </Button> : this.state.savedValue
    return (
      <span>
        {modifierLink}
        {modal}
      </span>
    )
  }

}
