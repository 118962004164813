import React from "react"
import {RIEInput} from "riek"
import DatePicker from "react-datepicker"
import {format, parse} from "date-fns"
import "./RIEDate.scss"
import PropTypes from "prop-types"

const outsideClickIgnoreClass = "react-datepicker-ignore-onclickoutside"

class CustomDatePicker extends DatePicker {

  // Method renderClearButton is overridden
  // and clear button className is extended by "react-datepicker-ignore-onclickoutside"
  // in order to prevent editing finish when clear button is pressed
  renderClearButton = () => {
    if (this.props.isClearable && this.props.selected != null) {
      return (
        <button
          type="button"
          className={"react-datepicker__close-icon " + outsideClickIgnoreClass}
          aria-label="Close"
          onClick={this.onClearClick}
          title={this.props.clearButtonTitle}
          tabIndex={-1}
        />
      )
    } else {
      return null
    }
  }
}

export default class RIEDate extends RIEInput {

  static propTypes = {
    dateFormat: PropTypes.string
  }

  static defaultProps = {
    dateFormat: "yyyy-MM-dd"
  }

  constructor(props) {
    super(props)
    this.state = {
      startDate: this.initStartDate()
    }
  }

  initStartDate() {
    return this.props.value ? parse(this.props.value, this.props.dateFormat, new Date()) : null
  }

  finishEditing = () => {
    const newValue = this.state.startDate ? format(this.state.startDate, this.props.dateFormat) : ""
    this.doValidations(newValue)
    if (!this.state.invalid && this.props.value !== newValue) {
      this.commit(newValue)
      this.cancelEditing(false)
    } else {
      this.cancelEditing()
    }
  }

  cancelEditing = (resetStartDate = true) => {
    this.setState({
      editing: false,
      invalid: false
    })
    if (resetStartDate) {
      this.setState({
        startDate: this.initStartDate()
      })
    }
  }

  handleBlur = (event) => {
    this.focusDatepickerInput()
  }

  handleChange = (date) => {
    this.setState({
      startDate: date
    })
  }

  handleClickOutside = (event) => {
    this.finishEditing()
  }

  focusDatepickerInput() {
    setTimeout(() => {
      if (this.refs && this.refs.input) {
        this.refs.input.setFocus()
      }
    }, 10)
  }

  renderEditingComponent = () => {
    return (
      <CustomDatePicker
        autoFocus={true}
        className={outsideClickIgnoreClass}
        dateFormat={this.props.dateFormat}
        disabled={(this.props.shouldBlockWhileLoading && this.state.loading)}
        disabledKeyboardNavigation={true}
        isClearable={true}
        onBlur={this.handleBlur.bind(this)}
        onChange={this.handleChange.bind(this)}
        onClickOutside={this.handleClickOutside.bind(this)}
        onKeyDown={this.keyDown}
        open={true}
        ref="input"
        selected={this.state.startDate}
      />
    )
  }
}