import React from "react"
import ReactDOM from "react-dom"
import { showErrors } from "../shared/utils"
import { openSimpleFormRemoteModal } from "../shared/remote_modals"
// import { StylingSuggestions } from "../../components/customers/StylingSuggestions"
import { registerOnReadyHandler } from "@nadinewest/common"
import { StylingProducts } from "./components/StylingProducts"

registerOnReadyHandler("stylings-show", () => {
  const shipmentId = $("span#shipment-id").data("shipment-id")
  let activeTab = null

  const handleShipmentItemClick = function(productId, variationId) {
    /* When we click on image or SKU on Shipment History we show product on right tab */
    reloadCartAndProduct(productId, variationId)
  }

  $(document).on("click", "a.thumbnail",  function() {
    /* reloading right pane with product descriptions when clicking on product thumbnail */
    $(".product_view").html("Loading...")
  })


  $("#styling-failed-button").click(function() {
    openSimpleFormRemoteModal(
      `/shipments/${shipmentId}/styling_failures/new`,
      null,
      null,
      function(data, status, xhr) {
        window.location.href = `/shipments?filter=to_style&assigned_to_me=1`
      }
    )
  })

  const reloadCartAndProduct = function(productId, variationId) {
    $.get(`styling/products/${productId}`, {variation_id: variationId})
  }

  const showErrorAddingRemovingShipmentItemAndReload = function(error) {
    alert("Error adding/removing shipment item: " + error)
    window.location.reload()
  }

  const selectors = "#remove-shipment-item-button, #out-of-stock-button,"
    + "#add-shipment-item-button, .add-shipment-item-link"
  $(document).on("ajax:success", selectors, function(event) {
    const data = event.detail[0]
    if (event.detail[1] == "OK") {
      if (data["productId"] && data["variationId"]) {
        reloadCartAndProduct(data["productId"], data["variationId"])
        window.reloadProductsTab($(".styling_variations_tabs.active"))
      } else if (data["error"]) {
        showErrorAddingRemovingShipmentItemAndReload(data["error"])
      }
    }
  }).on("ajax:error", selectors, function(event) {
    const errors = event.detail[0] != null ? event.detail[0].errors : null
    if (errors != null) {
      showErrors(errors)
    } else {
      showErrorAddingRemovingShipmentItemAndReload("Network error")
    }
  })

  // TODO: remove global, after refactor 'styling/product_show' action
  // This one made global for 'product_show.js.erb'
  window.reloadProductsTab = function(tab) {
    const fillPanel = function(prefix, data) {
      const panel = $("#" + prefix + "_panel")
      const panelContent =  $("#" + prefix + "_panel_content")
      const contentBody = $("#" + prefix + "_panel_content_body")
      ReactDOM.unmountComponentAtNode(contentBody.get(0))
      if (data.items.length>0) {
        if (contentBody != null) {
          ReactDOM.render(
            <StylingProducts
              shipmentId={shipmentId}
              items={data.items}
            />,
            contentBody.get(0)
          )
        }
        if (prefix == "available_") {
          panelContent.collapse("show")
        } else {
          panelContent.collapse("hide")
        }
        panel.show()
      } else {
        contentBody.html("")
        panel.hide()
      }
    }

    const category = tab.data("category")
    const size = tab.data("size")
    const maxPrice = $("#styling_max_price").val()
    const maxWeight = $("#styling_max_weight").val()
    const orderBy = $("#order_by").val()
    const skuNameSearch = $("#sku_name_search").val()
    const showNewCustomerExclusives = $("#show_new_customer_exclusives").prop("checked")
    const showVipCustomerExclusives = $("#show_vip_customer_exclusives").prop("checked")
    const showGoodCustomerExclusives = $("#show_good_customer_exclusives").prop("checked")
    $("#products_pane_all").hide()
    $("#loading_text").show()
    $.get(
      "styling/available_variations",
      {
        category_id: category,
        size: size,
        max_price: maxPrice,
        max_weight: maxWeight,
        order_by: orderBy,
        sku_name_search: skuNameSearch,
        show_new_customer_exclusives: showNewCustomerExclusives,
        show_vip_customer_exclusives: showVipCustomerExclusives,
        show_good_customer_exclusives: showGoodCustomerExclusives
      },
      function(data) {
        fillPanel("available_", {items: data.available})
        fillPanel("alternative_colors_matched_", {items: data.alternative_colors_matched})
        fillPanel("price_mismatched_", {items: data.price_mismatched})
        fillPanel("style_mismatched_", {items: data.style_mismatched})
        fillPanel("previously_sent_", {items: data.previously_sent})
        $("#loading_text").hide()
        $("#products_pane_all").show()
      }
    )
  }

  function stylingReady() {
    let topsTab = null
    const categoriesAll = $("#styling-categories").data("all")
    const categoriesSizes = $("#styling-categories").data("sizes")
    const categoryTop = $("#styling-categories").data("top")

    $.each(categoriesAll, function(index, categoryId) {
      const tab = $(".styling_variations_tabs[data-category=" + categoryId + "]")
      if (categoryId == categoryTop) {
        topsTab = tab
      }
      if (categoriesSizes.includes(categoryId)) {
        $("#category_" + categoryId + "_size").change(function() {
          tab.data("size", $(this).val())
          window.reloadProductsTab(tab)
        })
      }
    })

    $("#order_by").change(function() {
      window.reloadProductsTab(activeTab)
    })

    $("#styling_max_price").change(function() {
      window.reloadProductsTab(activeTab)
    })

    $("#styling_max_weight").change(function() {
      window.reloadProductsTab(activeTab)
    })

    $("#sku_name_search").change(function() {
      window.reloadProductsTab(activeTab)
    })

    $("#show_new_customer_exclusives").change(function() {
      $("#show_vip_customer_exclusives").prop("checked", false)
      $("#show_good_customer_exclusives").prop("checked", false)
      window.reloadProductsTab(activeTab)
    })

    $("#show_vip_customer_exclusives").change(function() {
      $("#show_new_customer_exclusives").prop("checked", false)
      $("#show_good_customer_exclusives").prop("checked", false)
      window.reloadProductsTab(activeTab)
    })

    $("#show_good_customer_exclusives").change(function() {
      $("#show_vip_customer_exclusives").prop("checked", false)
      $("#show_new_customer_exclusives").prop("checked", false)
      window.reloadProductsTab(activeTab)
    })

    $(".styling_variations_tabs").on("show.bs.tab", function (e) {
      activeTab = $(this)
      window.reloadProductsTab(activeTab)
    })

    if (topsTab) {
      topsTab.tab("show")
    }
  }

  $(document).on("page:load", stylingReady)
  stylingReady()
})
