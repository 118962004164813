import { Controller } from "@hotwired/stimulus"

// Reload currentDataTable
export default class extends Controller {
  static values = { reload: Boolean, default: false }

  connect() {
    if (this.reloadValue) {
      window.currentDataTable.ajax.reload()
    }
  }
}
