import React from "react"
import ReactDOM from "react-dom"
import {InPlaceEditor} from "components/utils/InPlaceEditor"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("admin-settings-show", () => {
  const elem = $("#styling-instructions-editor")
  ReactDOM.render(
    <InPlaceEditor
      controlType="textarea"
      property="value"
      recordType="system_parameter"
      value={elem.data("value")}
      rows={40}
      updateURL={elem.data("url")}
    />,
    elem.get(0)
  )
})
