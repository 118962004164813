import React from "react"
import ThirdPartyStatusBase from "./ThirdPartyStatusBase"
import { doGet } from "@nadinewest/common/javascript/src"

const FEDEX_STATUS_OK = "OK"

export default class FedExStatus extends ThirdPartyStatusBase {
  static defaultProps = {
    statusTitle: "FedEx",
    statusDetailsUrl: "https://www.shippingapimonitor.com/history.html?api=fedex"
  }

  constructor(props) {
    super(props)
    this.state = {
      status: null
    }
  }

  updateStatus() {
    this.setState({
      status: "Loading..."
    })
    doGet("/api_status/fedex", {}, (response) => {
      this.setState({
        status: response.ok ? FEDEX_STATUS_OK : "FAILED",
        statusDate: new Date()
      })
    })
  }

  renderStatus() {
    return (
      <h3>
        API:&nbsp;
        <span
          className={this.state.status === FEDEX_STATUS_OK ? "text-success" : "text-danger"}
        >
          {this.state.status}
        </span>
      </h3>
    )
  }
}
