import { Controller } from "@hotwired/stimulus"
import { openSimpleFormRemoteModal } from "../../src/shared/remote_modals"

export default class extends Controller {
  static targets = ["table"]

  connect() {
    this.initializeDataTable()
  }

  initializeDataTable() {
    $(this.tableTarget).DataTable()
  }

  addBrand(e) {
    e.preventDefault()
    openSimpleFormRemoteModal("/brands/new", null, null, () => window.location.href = "/brands")
  }

  editBrand(e) {
    e.preventDefault()
    const brandId = e.currentTarget.dataset.brandId
    openSimpleFormRemoteModal(`/brands/${brandId}/edit`, null, null, () => window.location.href = "/brands")
  }
}
