import { openSimpleFormRemoteModal } from "../shared/remote_modals"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("aisles-index", () => {
  $(".btn-new-aisle").click((e) => {
    openSimpleFormRemoteModal(
      "/aisles/new",
      null,
      null,
      function () {
        window.location.href = "/aisles"
      }
    )
  })

  $(".edit-aisle").click((e) => {
    const aisleId = $(e.target).data("aisle-id")
    openSimpleFormRemoteModal(
      `/aisles/${aisleId}/edit`,
      null,
      null,
      function () {
        window.location.href = "/aisles"
      }
    )
  })

  window.currentDataTable = $("#aisles-table").DataTable()
})
