import { registerOnReadyHandler } from "@nadinewest/common"
import client from "braintree-web/client"
import hostedFields from "braintree-web/hosted-fields"

registerOnReadyHandler("customers-edit_cc", () => {
  const initializeHostedFieldsCallback = function (hostedFieldsErr, hostedFieldsInstance) {
    if (hostedFieldsErr) {
      console.error(hostedFieldsErr)
      return
    }
    $("#submit-cc-button").click(function(event) {
      event.preventDefault()

      hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
        if (tokenizeErr) {
          console.error(tokenizeErr)
          alert("Failed to tokenize credit card info")
          return
        }
        $("#payment_method_nonce").val(payload.nonce)
        $("#credit-card-form").submit()
      })
    })
  }
  const createClientCallback = function (clientErr, clientInstance) {
    if (clientErr) {
      console.error(clientErr)
      alert("Failed to initialize Braintree Client")
      return
    }
    const options = {
      client: clientInstance,
      styles: {
        ".invalid": {"color": "#a94442"},
        ".valid": {"color": "green"}
      },
      fields: {
        number: {selector: "#cc-number", placeholder: "Credit Card Number"},
        cvv: {selector: "#cc-cvv", placeholder: "CVV"},
        expirationDate: {selector: "#cc-exp", placeholder: "MM/YY"}
      }
    }

    hostedFields.create(options, initializeHostedFieldsCallback)
  }

  $.get(
    "/braintree/token",
    {},
    function(token, status, xhr) {
      client.create({authorization: token}, createClientCallback)
    },
    "text"
  )
})
