import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import { renderLink, selectedValues } from "src/shared/utils"
import { openSimpleFormRemoteModal } from "src/shared/remote_modals"
import { formatDate, formatMoney } from "@nadinewest/common"
import {formatTimestampAsDate} from "utils/formatters"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("orders-index", () => {

  window.currentDataTable = $("#orders-table").DataTable({
    order: [[0, "desc"]],
    columns: [
      { data: "id" },
      {
        data: "createdAt",
        render: (data, type, row) =>
          renderLink(
            `/orders/${row.id}`,
            formatTimestampAsDate(data)
          )
      },
      { data: "expectedArrivalDate", render: formatDate},
      {
        data: "vendor",
        render: (data, type, row) =>
          renderLink(
            `/vendors/${data.id}`,
            data.name,
            { target: "_blank" }
          )
      },
      { data: "vendorOrderId"},
      { data: "status" },
      { data: "orderType" },
      { data: "shippingCost", render: formatMoney},
      { data: "subtotals",
        createdCell: function (cell, cellData, rowData) {
          ReactDOM.render(
            <React.Fragment>
              { cellData.ordered && <div>{formatMoney(cellData.ordered)}</div> }
              { cellData.arrived && <div className='text-danger'>arrived: {formatMoney(cellData.arrived)}</div> }
            </React.Fragment>
            , cell)
        }},
      { data: "items_stats", sortable: false,
        createdCell: function (cell, cellData, rowData) {
          ReactDOM.render(
            <React.Fragment>
              {cellData.map((statsRow, index) => (
                <div className='row min-gutter' key={index}>
                  <div className='col-sm-3'>
                    {statsRow.category}
                    {statsRow.size && <span> / {statsRow.size}</span>}
                  </div>
                  <div className='col-sm-3'>
                    {statsRow.ordered}
                  </div>
                  <div className='col-sm-3'>
                    {statsRow.arrived}
                  </div>
                  <div className='col-sm-3'>
                    {statsRow.accepted}
                  </div>
                </div>
              ))}
            </React.Fragment>
            , cell)
        }
      }
    ],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/orders/search",
      data: function(d) {
        const filter = $("#orders_filter_panel_content_body")
        d.vendor_id = filter.find("#vendor_id").val()
        d["statuses[]"] = selectedValues(filter.find("input[name='status']:checked"))
        d.created_at_from = filter.find("#created_at_from").val()
        d.created_at_to = filter.find("#created_at_to").val()
        d.expected_arrival_date_from = filter.find("#expected_arrival_date_from").val()
        d.expected_arrival_date_to = filter.find("#expected_arrival_date_to").val()
        d.vendor_order_id = filter.find("#vendor_order_id").val()
        d["types[]"] = selectedValues(filter.find("input[name='type']:checked"))
      }
    }
  })
  $("#orders_filter_panel_content_body").find("select, input").change(function(event) {
    $("#orders-table").DataTable().ajax.reload()
  })
  $("#new_product_button").click(function() {
    openSimpleFormRemoteModal(
      "/orders/new",
      function() {
        this.find(".datepicker").datepicker({format: "yyyy-mm-dd"})
      },
      null,
      function(data) {
        window.location.href = data.url
      }
    )
  })

})
