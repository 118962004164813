import { Controller } from "@hotwired/stimulus"
import QrScanner from "qr-scanner"

// qr-scanner
//
export default class extends Controller {
  static targets = [ "video", "cameraFrame", "result", "showButton", "startButton", "stopButton", "form" ]

  show(event) {
    event.preventDefault()

    if (QrScanner.hasCamera()) {
      if (window.qrScanner) {
        window.qrScanner.destroy()
        window.qrScanner = null
      }
      console.log(window.qrScanner)
      $(this.cameraFrameTarget).show()
      $(this.showButtonTarget).hide()
      $(this.startButtonTarget).show()

      window.qrScanner = new QrScanner(
        this.videoTarget,
        result => this.process(result),
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          onDecodeError: error => {
            this.resultTarget.textContent = error
          }
        }
      )
    }
  }

  start(event) {
    event.preventDefault()

    window.qrScanner.start()
    $(this.startButtonTarget).hide()
    $(this.stopButtonTarget).show()
  }

  stop(event) {
    event.preventDefault()

    window.qrScanner.stop()
    $(this.startButtonTarget).show()
    $(this.stopButtonTarget).hide()
  }

  process(result) {
    console.log(result.data)
    window.qrScanner.stop()
    $(this.startButtonTarget).show()
    $(this.stopButtonTarget).hide()

    this.resultTarget.textContent = result.data

    $(this.formTarget).find("input").val(result.data)

    this.formTarget.requestSubmit()
  }

  disconnect() {
    if (window.qrScanner) {
      window.qrScanner.stop()
      window.qrScanner.destroy()
      window.qrScanner = null
    }
  }
}
