import React from "react"
import ThirdPartyStatusBase from "./ThirdPartyStatusBase"

const PRINT_NODE_STATUS_OK = "OK"

export default class PrintNodeStatus extends ThirdPartyStatusBase {

  static defaultProps = {
    statusTitle: "PrintNode",
    statusDetailsUrl: "https://www.printnode.com/en/status"
  }

  constructor(props) {
    super(props)
    this.state = {
      status: null
    }
  }

  updateStatus() {
    this.setState({
      status: "Loading..."
    })
    fetch("https://api.printnode.com/ping").
      then(response => {
        this.setState({
          status: response.ok ? PRINT_NODE_STATUS_OK : `FAIL: ${response.status}`,
          statusDate: new Date()
        })
      }).
      catch(error => {
        console.error("Unexpected error while trying to check PrintNode API status: ", error.message)
        this.setState({
          status: "Unexpected Error",
          statusDate: new Date()
        })
      })
  }

  renderStatus() {
    return (
      <h3>
        API:&nbsp;
        <span
          className={this.state.status == PRINT_NODE_STATUS_OK ? "text-success" : "text-danger"}
        >
          {this.state.status}
        </span>
      </h3>
    )
  }
}