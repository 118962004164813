import React from "react"
import {Col, Row} from "react-bootstrap"
import HerokuStatus from "./HerokuStatus"
import PitneyBowesStatus from "./PitneyBowesStatus"
import PrintNodeStatus from "./PrintNodeStatus"
import TaxJarStatus from "./TaxJarStatus"
import FedExStatus from "./FedExStatus"

export default class ThirdPartyStatus extends React.PureComponent {

  render() {
    return (
      <Row>
        <Col sm={6}>
          <PrintNodeStatus/>
          <PitneyBowesStatus/>
        </Col>
        <Col sm={6}>
          <TaxJarStatus/>
          <HerokuStatus/>
        </Col>
        <Col sm={6}>
          <FedExStatus/>
        </Col>
      </Row>
    )
  }
}
