import React from "react"
import ReactDOM from "react-dom"
import { renderLink } from "src/shared/utils"
import { registerOnReadyHandler } from "@nadinewest/common"
import { ProductImage } from "../../../components/products/ProductImage"

registerOnReadyHandler("admin-testimonials-index", () => {

  window.currentDataTable = $("#testimonials_table").DataTable({
    order: [[2, "asc"]],
    columns: [
      {
        data: "id",
        title: "ID"
      },
      {
        data: "enabled",
        title: "Enabled",
        render: renderEnabledGlyph
      },
      {
        data: "name",
        title: "Display Name",
        render: (data, type, row) =>
          renderLink(
            `/admin/testimonials/${row.id}`,
            row.name
          )
      },
      {
        data: "customer",
        title: "Customer",
        render: (data, type, row) =>
          renderLink(
            `/customers/${row.customer.id}?tab=testimonials`,
            row.customer.name
          )
      },
      {
        data: "image_url",
        title: "Photo",
        sortable: false,
        searchable: false,
        defaultContent: "",
        createdCell: function (cell, cellData, rowData) {
          if (cellData != "") {
            ReactDOM.render(
              <ProductImage
                imageURL={cellData}
                size="small"
              />,
              cell
            )
          }
        }
      },
      {
        data: "content",
        title: "Text"
      }
    ],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/admin/testimonials/search",
      data: function(d) {
        const filter = $("#testimonials_filter_panel_content_body")
        d.enabled = filter.find("#enabled").val()
        d.has_photo = filter.find("#has_photo").val()
        d.name = filter.find("#name").val()
      }
    }
  })

  $("#testimonials_filter_panel_content_body").find("select, input").change(function(event) {
    $("#testimonials_table").DataTable().ajax.reload()
  })

  function renderEnabledGlyph(data) {
    return data ? $("<span/>").append($("<span class='material-icons'>done</span>")).html() : null
  }

  $("#testimonials_table thead").addClass("table-info")
})
