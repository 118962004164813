import React from "react"
import ThirdPartyStatusBase from "./ThirdPartyStatusBase"

export default class HerokuStatus extends ThirdPartyStatusBase {

  static defaultProps = {
    statusTitle: "Heroku",
    statusDetailsUrl: "https://status.heroku.com"
  }

  constructor(props) {
    super(props)
    this.state = {
      developmentStatus: null,
      productionStatus: null
    }
  }

  updateStatus() {
    this.setState({
      productionStatus: "Loading...",
      developmentStatus: "Loading..."
    })
    fetch("https://status.heroku.com/api/v3/current-status").
      then(response => {
        response.ok ?
          response.json().then(result => {
            this.setState({
              productionStatus: result.status.Production.toUpperCase(),
              developmentStatus: result.status.Development.toUpperCase(),
              statusDate: new Date()
            })
          }) : this.setState({
            productionStatus: `FAIL: ${response.status}`,
            developmentStatus: `FAIL: ${response.status}`,
            statusDate: new Date()
          })
      }).
      catch(error => {
        console.error("Unexpected error while trying to check Heroku API status: ", error.message)
        this.setState({
          productionStatus: "Unexpected Error",
          developmentStatus: "Unexpected Error",
          statusDate: new Date()
        })
      })
  }

  renderStatusColor(status) {
    return status == "GREEN" ? "text-success" : (status == "YELLOW" ?  "text-warning" : "text-danger")
  }

  renderStatus() {
    return (
      <React.Fragment>
        <h3>
          Production:&nbsp;
          <span
            className={this.renderStatusColor(this.state.productionStatus)}
          >
            {this.state.productionStatus}
          </span>
        </h3>
        <h3>
          Development:&nbsp;
          <span
            className={this.renderStatusColor(this.state.developmentStatus)}
          >
            {this.state.developmentStatus}
          </span>
        </h3>
      </React.Fragment>
    )
  }
}