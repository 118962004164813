import { Controller } from "@hotwired/stimulus"

// Need to auto-submit a form on change?
// Connects to -> data: {controller: 'submittable'}
// Use on input elements -> data: {action: 'change->submittable#submit'}
export default class extends Controller {
  submit() {
    setTimeout(() => {
      this.element.requestSubmit()
    }, 150)
  }
}
