import { openRemoteModal } from "src/shared/remote_modals"
import { selectedValues } from "src/shared/utils"
import { formatMoney } from "@nadinewest/common"
import { ProductImage } from "components/products/ProductImage"
import { SkuWithCategory } from "components/products/SkuWithCategory"
import React from "react"
import ReactDOM from "react-dom"

/**
 * Function used to show search variation dialog
 * @param options - additional filters as key-value set, they will be passed as parameters both into search request
 *  and to initial search_form request used to render search form.
 * @param afterLoadCallback - function called after search form is loaded. Can be used to add new elements to form.
 * @param okCallback - function called when OK button is clicked. Accepts array of selected ids. If returns true,
 *  modal will be closed, otherwise modal will be left open.
 */
export function openVariationsSearchDialog(options, afterLoadCallback, okCallback) {
  const _afterLoadCallback = function() {
    const selectionType = options["multiselect"] == true ?"checkbox" : "radio"
    window.currentDataTable = this.find("#vsf_table").DataTable({
      order: [[1, "desc"]],
      serverSide: true,
      serverMethod: "post",
      ajax: {
        url: "/variations/search",
        data: function (d) {
          // Copying initial options
          for (const option in options) {
            d[option] = options[option]
          }
          // Setting filters selected in search form
          d.category_id = $("#vsf_category_id").val()
          d.vendor_sku = $("#vsf_vendor_sku").val()
          d.size = $("#vsf_size").val()
          d.product_name = $("#vsf_product_name").val()
          d.variation_id = $("#vsf_variation_id").val()
          d.location = $("#vsf_location").val()
        }
      },
      pageLength: 10,
      filter: false,
      lengthChange: false,
      columns: [
        {
          data: "id",
          title: "Select",
          className: "item-selection-box",
          sortable: false
        },
        {
          data: "id",
          title: "SKU"
        },
        {
          title: "Image",
          data: "product_flavor.image_url",
          className: "item-image",
          sortable: false
        },
        {
          title: "Style Number",
          data: "product.sku",
          className: "item-sku"
        },
        {
          title: "Vendor SKU",
          data: "product.vendor_sku"
        },
        {
          title: "Product Name",
          data: "product.name",
        },
        {
          title: "Flavor",
          data: "product_flavor.name",
          sortable: false
        },
        {
          title: "Size",
          data: "size"
        },
        {
          title: "Price",
          data: "product.suggested_price",
          render: formatMoney
        },
        {
          title: "Picking Location",
          data: "picking_location.name",
          defaultContent: "",
        }
      ],
      rowCallback(row, data) {
        ReactDOM.render(
          <ProductImage
            imageURL={data.product_flavor.image_url}
            size="tiny"
          />,
          $(row).find(".item-image").get(0)
        )
        const p = data.product
        ReactDOM.render(
          <SkuWithCategory
            sku={p.sku}
            categoryName={p.category.name}
            productId={p.id}
          />,
          $(row).find(".item-sku").get(0)
        )
        const html =
          `<input name="variation_id" type="${selectionType}" value=${data.id}
          class='form-check-input checkbox-2x ml-1'/>`
        $(row).find(".item-selection-box").
          html(html)
      }
    })

    $(this).find("#vsf_category_id").change(function() {
      const categoryId = parseInt($(this).val())
      if (Product.CATEGORIES_WITH_SIZE.includes(categoryId)) {
        $("#vsf_size").attr("disabled", false)
      } else {
        $("#vsf_size").val("")
        $("#vsf_size").attr("disabled", true)
      }
    })

    $(this).find("#vsf_category_id").change()

    $(this).find(".vsf-filter input, .vsf-filter select").change(function(e) {
      $("#vsf_table").DataTable().ajax.reload()
    })

    if (afterLoadCallback !== null) {
      afterLoadCallback.call(this)
    }
  }

  const _okCallback = function() {
    const variationIds = selectedValues("#vsf_table input[name='variation_id']:checked")

    if (variationIds.length == 0) {
      alert("Select at least one record")
      return false
    } else {
      return okCallback.call(this, variationIds)
    }
  }

  const url = "/variations/search_form?" + $.param(options)
  openRemoteModal(url, _afterLoadCallback, _okCallback)
}
