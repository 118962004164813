import React from "react"
import PropTypes from "prop-types"
import {Col, Row} from "react-bootstrap"

export function StylingProducts(props) {
  const products = []
  props.items.forEach(function(i) {
    const showLinkClassName = `show-product-info thumbnail variation_${i.id}`
    const showLink = `/shipments/${props.shipmentId}/styling/products/${i.productId}?variation_id=${i.id}`
    const addLinkClassName = `add-shipment-item-link variation_${i.id}`
    const addLink = `/shipments/${props.shipmentId}/items?variation_id=${i.id}`
    const icon = i.featured ? <span className="material-icons">star</span> : null
    products.push(
      <Col sm={2} xs={4} key={i.id} className="product-variation-button">
        <a className={addLinkClassName} data-remote="true" data-method="post" href={addLink}>&#43;</a>
        <a className={showLinkClassName} data-remote="true" href={showLink}>
          <div className="product-image product-image-square">
            <img src={i.imageUrl} alt={i.id}/>
            {icon}
          </div>
        </a>
      </Col>
    )
  })
  return <Row>
    {products}
  </Row>
}

StylingProducts.propTypes = {
  items: PropTypes.array,
  shipmentId: PropTypes.number.isRequired
}
