import { registerOnReadyHandler } from "@nadinewest/common"

const changeVisibility = function (selector, show) {
  if (show) {
    $(selector).show()
  } else {
    $(selector).hide()
  }

  $("th.forecast_header").attr("colspan", show ? 5 : 1)
  $("th.forecast_footer_title").attr("colspan", show ? 6 : 4)
}

registerOnReadyHandler(
  [
    "reports-inventory_forecasts-show",
    "inventory_reports-ordering_calculator",
    "inventory_reports-inventory_cashflow",
    "inventory_reports-ordering_calculator_long_term",
  ],
  () => {
    const inputSelector = $(".nw-toggle-report-details").first()
    const dataSelector = $(inputSelector).data("details-selector")

    // Checkbox used to toggle report details
    $("input.nw-toggle-report-details").on("change", function () {
      const show = $(this).prop("checked")

      changeVisibility(dataSelector, show)
    })

    // Link used to toggle report details
    $("a.nw-toggle-report-details").on("click", function () {
      const isActive = $(this).hasClass("active")

      changeVisibility(dataSelector, !isActive)

      $(this).toggleClass("active", !isActive)
    })

    // Hide report details by default
    changeVisibility(dataSelector, false)
  }
)
