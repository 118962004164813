import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import { doPost } from "@nadinewest/common"

export class CreateShipmentButton extends React.PureComponent {

  static propTypes = {
    customerId: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      disabled: false,
      errorMessage: null,
      shipmentId: null
    }
  }

  createCallback(resp) {
    if (resp.ok) {
      resp.json().then(json => {
        if (json.result == "ERROR") {
          this.setState({errorMessage: json.error_message})
        } else {
          this.setState(
            {
              shipmentId: json.shipment_id,
              shipmentIndex: json.shipment_index,
              shipmentStatus: json.shipment_status
            }
          )
        }
      })
    } else {
      this.setState({errorMessage: "ERROR: failed to create shipment"})
    }
  }

  handleCreateClick() {
    if (!this.state.disabled) {
      this.setState(
        {disabled: true}
      )
      doPost(`/customers/${this.props.customerId}/create_new_shipment`,
        null,
        this.createCallback.bind(this)
      )
    }
  }

  render() {
    let resultRender
    if (this.state.errorMessage) {
      resultRender = <div className={"text-danger"}>
          ERROR: {this.state.errorMessage}
      </div>
    } else if (this.state.shipmentId) {
      resultRender = <React.Fragment>
        <a href={`/shipments/${this.state.shipmentId}`} target={"_blank"} rel="noopener noreferrer">
          Shipment #{this.state.shipmentIndex}
        </a>
        <div>
          ({this.state.shipmentStatus})
        </div>
      </React.Fragment>
    } else {
      resultRender = <Button
        variant="primary"
        disabled={this.state.disabled}
        onClick={this.handleCreateClick.bind(this)}
      >Create Shipment</Button>
    }
    return resultRender
  }
}
