import React from "react"
import PropTypes from "prop-types"

// renders array of icons
export function IconsSet(props) {
  const  icons = []
  props.icons.forEach(function(i) {
    icons.push(
      <span
        key={i.icon}
        className={`nw-icon nw-icon-${i.icon}`}
        data-toggle={"tooltip"}
        title={i.hint}
      />
    )
  })
  return <div>
    {icons}
  </div>
}

IconsSet.propTypes = {
  icons: PropTypes.array
}
