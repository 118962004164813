/**
 * This function is intended to open modal dialog using remote url
 * @param url - url to load modal content from
 * @param prefix - prefix used when modal content is generated (see dialog layout in view/shared)
 * @param afterLoadCallback - called after modal wil be loaded. 'this' will be set to jqueryObject, representing body
 *  of dialog
 * @param okCallback - called after ok button clicked. If returns true then modal will be closed, otherwise modal
 *  will be left open. 'this' will be  set to jqueryObject, representing body of dialog
 *
 */
export function openRemoteModal(url, afterLoadCallback, okCallback) {
  // We need container to host dialog content
  const container = $("#nw_remote_dialog .modal-body")
  container.data("afterLoadCallback", afterLoadCallback)
  container.data("okCallback", okCallback)

  container.load(url, function (response, status, xhr) {
    const title = xhr.getResponseHeader("NW_REMOTE_DIALOG_TITLE")
    $("#nw_remote_dialog_title").html(title)
    const width = xhr.getResponseHeader("NW_REMOTE_DIALOG_WIDTH")
    $("#nw_remote_dialog .modal-dialog").css("min-width", width)
    const hideCancel = xhr.getResponseHeader("NW_REMOTE_DIALOG_CANCEL") === "false"
    if (hideCancel) {
      $("#nw_remote_dialog .modal-header button.close").addClass("d-none")
      $("#nw_remote_dialog_cancel_button").addClass("d-none")
    } else {
      $("#nw_remote_dialog .modal-header button.close").removeClass("d-none")
      $("#nw_remote_dialog_cancel_button").removeClass("d-none")
    }
    setRemoteModalBody(response)
  })
  // opening loaded modal
  $("#nw_remote_dialog").attr("tabIndex", -1)  // Enables close on Esc
  $("#nw_remote_dialog").modal()
}

/**
 * This method is used to simplify working with remotely loading simple_form forms
 * Loaded content must contain exactly one form with .simple_form class and data-remote=true.
 * If submission of form succeed server should return empty response with success status, in this case modal will be
 * closed.
 * If submission of form failed due to validation errors server should render form with validation error messages
 * (as simple_form does by default) and with unprocessable_entity (422) status.
 * @param url - url to load form from
 * @param afterLoadCallback - [optional] any additional form initializer.
 * @param okCallback - [optional] will be called before form submission. Form will be submitted only if this
 *  callback returns true. If it returns false, no submission will be made and modal will be left open.
 * @param afterSuccessCallback [optional] will be called if form submission succeed. Should be used to refresh UI
 *  after successful data modification. If returns false modal will be left open.
 *
 */
export function openSimpleFormRemoteModal(url, afterLoadCallback, okCallback, afterSuccessCallback) {
  const _afterLoadCallback = function() {
    if (typeof afterLoadCallback !== "undefined" && afterLoadCallback !== null) {
      afterLoadCallback.call(this)
    }

    const form = this.find("form.simple_form")
    form.on("ajax:success", function(e) {
      // see https://edgeguides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
      const data = e.detail[0]
      const status = e.detail[1]
      const xhr = e.detail[2]

      let result = false
      if (typeof afterSuccessCallback !== "undefined" && afterSuccessCallback !== null) {
        result = afterSuccessCallback.call(this, data, status, xhr)
      }
      if (result !== false) {
        hideRemoteModal()
      }
    }).on("ajax:error", function(e) {
      // see https://edgeguides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
      const xhr = e.detail[2]
      // Reloading content of modal with response. Form with validation errors is expected.
      setRemoteModalBody(xhr.responseText)
    })

    // If we use jquery $(form).submit() function for remote form submission it will be blocked if some
    // html-validation fails (e.g. empty required field). But no warnings will be shown to user. So to avoid
    // this we add hidden submit button to form and will do submission by imitating click on this button.
    // This way native html5 validation messages will be shown.
    form.append('<input id="nw_remote_simple_form_modal_submit_button" type="submit" class="d-none"></input>')
  }

  const _okCallback = function() {
    if (typeof okCallback !== "undefined" && okCallback !== null) {
      if (okCallback.call(this) === false) {
        // Form submission blocked by okCallback
        return false
      }
    }
    $(this).find("form.simple_form input#nw_remote_simple_form_modal_submit_button").click()
    // Modal should be left open because form submission can fail. It will be closed in ajax:success handler
    return false
  }

  openRemoteModal(url, _afterLoadCallback, _okCallback)
}

/**
 * This method is used to set body of remotely loaded dialog
 * it can be used to redraw content of already opened dialog (e.g. after failed form submission)
 * @param content html content to place inside dialog body
 */
function setRemoteModalBody(content) {
  const container = $("#nw_remote_dialog .modal-body")
  container.html(content)
  // additional initialization to be done after dialog is loaded: creating controls, setting callbacks etc.
  const afterLoadCallback = container.data("afterLoadCallback")
  if (afterLoadCallback !== null) {
    afterLoadCallback.call(container)
  }
}

/**
 * This function can be called to close remote modal from js
 */
export function hideRemoteModal() {
  $("#nw_remote_dialog").modal("hide")
}
