import { Controller } from "@hotwired/stimulus"
import { doGet } from "@nadinewest/common/javascript/src"

// Controller for the Assign Shipments to Stylists form, resetting the form and reloading Shipment index datatable
export default class extends Controller {
  static values = {
    reload: Boolean,
    default: false,
    allTiersTitle: String,
    newCustomersTitle: String
  }

  connect() {
    $("#customer_tiers").select2({ placeholder: this.allTiersTitleValue })

    $("#customer_segments").on("select2:select select2:unselect", function () {
      const event = new Event("change", { bubbles: true }) // fire native change event
      this.dispatchEvent(event)
    })

    if (this.reloadValue) {
      this.reload()
    }
  }

  reset() {
    setTimeout(() => {
      $("#amount").val(100)
      $("#created_date_from").val(null)
      $("#created_date_to").val(null)
      this.clearSelect2Field("#stylist_ids")
      this.clearSelect2Field("#sizes")
      this.clearSelect2Field("#customer_segments")
      this.clearSelect2Field("#customer_tiers")
    }, 300)
  }

  reload() {
    doGet(
      "/stylists/assignable",
      {},
      resp => {
        if (resp.ok) {
          resp.json().then(json => {
            // save current selection
            const currentSelection = $("#stylist_ids").select2("data").map(record => {
              return record.id
            })

            // refresh data
            const data = json.map((record, index) => {
              return { id: record[1], text: record[0] }
            })
            $("#stylist_ids").empty().select2({
              data: data
            })

            // re-apply selection after refreshing data
            $("#stylist_ids").val(currentSelection).trigger("change")
          })
        }
      }
    )
  }

  enableCustomerTierField() {
    const newCustomerTypeSelected = $("#customer_segments").
      find(":selected").
      text().
      includes(this.newCustomersTitleValue)
    const customerTiersField = $("#customer_tiers")

    customerTiersField.select2({ disabled: !newCustomerTypeSelected, placeholder: this.allTiersTitleValue })

    if (!newCustomerTypeSelected) {
      this.clearSelect2Field(customerTiersField)
    }
  }

  clearSelect2Field(fieldSelector) {
    $(fieldSelector).val(null).trigger("change")
  }
}
