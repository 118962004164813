/* OMS */
/* This file will be plugged into every page using default layout */
import $ from "jquery"
import "bootstrap"

import "./icons"
import "./application.scss"

import Rails from "rails-ujs"
import "@hotwired/turbo-rails"

import {loadSentry} from "./sentry.js"

import "select2"
import "bootstrap-datepicker"
import "datatables.net"
import "datatables.net-bs4"
import "datatables.net-fixedheader"
import "datatables.net-fixedheader-bs4"
import {reloadPanel, reloadTab} from "src/shared/utils"
import {hideRemoteModal} from "src/shared/remote_modals"

import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css"
import "datatables.net-bs4/css/dataTables.bootstrap4.css"
import "datatables.net-bs4/js/dataTables.bootstrap4.js"
import "datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css"
import "datatables.net-fixedheader-bs4/js/fixedHeader.bootstrap4.js"
import "@nadinewest/common/stylesheets/stylequiz-images"
import "@nadinewest/common/stylesheets/colors-linear-gradients.scss"
import "../customers/quiz.scss"

import {loadTooltips, decideFocusedInput, showDatatablesFixedHeaders} from "../../utils/common"

// Individual scripts for different pages, please use alphabethical order when possible
import "../admin/discount_codes/index"
import "../admin/gift_cards/index"
import "../admin/print_stations/index"
import "../admin/settings/show"
import "../admin/settings/styling_instructions"
import "../admin/testimonials/index"
import "../admin/users/index"
import "../aisles/index"
import "../customers/edit_cc"
import "../customers/index"
import "../customers/show"
import "../dashboard/index"
import "../dashboard/third_party_status"
import "../inventory_reports/index"
import "../inventory_reports/ordering_calculator_settings"
import "../messages/incoming_index"
import "../one_way_shipments/index"
import "../orders/index"
import "../orders/show"
import "../pick_batches/index"
import "../product_groups/index"
import "../products/index"
import "../products/show"
import "../profiles/show"
import "../shipments/index"
import "../shipments/show"
import "../stylings/show"
import "chartkick/highcharts"

// ActionCable channels
import "../../channels"

// Stimulus controllers
import "../../controllers/oms"

// Simple global functions
import "./custom"

// Making jQuery available globally to make it available in other packs and to not import it in every module
window.$ = window.jQuery = $

Rails.start() // Rails ujs

/* Datatables */

// Default settings for dataTables
$.extend(true, $.fn.dataTable.defaults, {
  dom: '<"row"<"col-sm-5"i><"col-sm-7"p><"clear">>rt<"row"<"col-sm-2"l><"col-sm-4"i><"col-sm-6"p><"clear">>',
  pageLength: 50,
  lengthMenu: [[50, 100], [50, 100]],
  fixedHeader: true,
  language: {
    processing: "<div class='text-center'><div role='status' class='spinner-border'></div></div>"
  },
  processing: true
})
// The currently inited DataTable
window.currentDataTable = null
// List of all datatables on the page, including in tabs
window.dataTables = {}

/* Reloadable */

function runReloadables() {
  loadTooltips() // Bootstrap tooltips
}

/* Application activity spinner */

function showSpinner() {
  $("#spinner").show()
  $("#logo").hide()
}

function hideSpinner() {
  $("#spinner").hide()
  $("#logo").show()
}

/* Turbo events */

// :click fires when you click a Turbo-enabled link. The clicked element is the event target.
// Access the requested location with event.detail.url.
// Cancel this event to let the click fall through to the browser as normal navigation.
// $(document).on("turbo:click", function() {
//   console.log("turbo:click")
// })

// :before-visit fires before visiting a location, except when navigating by history.
// Access the requested location with event.detail.url.
// Cancel this event to prevent navigation.
// $(document).on("turbo:before-visit", function() {
//   console.log("turbo:before-visit")
// })

// :visit fires immediately after a visit starts.
// Access the requested location with event.detail.url and action with event.detail.action.
// $(document).on("turbo:visit", function() {
//   console.log("turbo:visit")
// })

// :submit-start fires during a form submission.
// Access the FormSubmission object with event.detail.formSubmission.
// Abort form submission (e.g. after validation failure) with event.detail.formSubmission.stop().
// (use event.originalEvent.detail.formSubmission.stop() if you’re using jQuery).
// $(document).on("turbo:submit-start", function() {
//   console.log("turbo:submit-start")
// })

// :before-fetch-request fires before Turbo issues a network request to fetch the page.
// Access the requested location with event.detail.url and the fetch options object with event.detail.fetchOptions.
// This event fires on the respective element (turbo-frame or form element) which triggers it and can be accessed
// with event.target property. Request can be canceled and
// continued with event.detail.resume (see Pausing Requests).
$(document).on("turbo:before-fetch-request", function() {
  // console.log("turbo:before-fetch-request")

  showSpinner()
})

// :before-fetch-response fires after the network request completes.
// Access the fetch options object with event.detail.
// This event fires on the respective element (turbo-frame or form element)
// which triggers it and can be accessed with event.target property.
$(document).on("turbo:before-fetch-response", function() {
  // console.log("turbo:before-fetch-response")

  hideSpinner()
})

// :submit-end fires after the form submission-initiated network request completes.
// Access the FormSubmission object with event.detail.formSubmission
// along with FormSubmissionResult properties included within event.detail.
// $(document).on("turbo:submit-end", function() {
//   console.log("turbo:submit-end")
// })

// :before-cache fires before Turbo saves the current page to cache.
// $(document).on("turbo:before-cache", function() {
//   console.log("turbo:before-cache")
// })

// :before-render fires before rendering the page.
// Access the new <body> element with event.detail.newBody.
// Rendering can be canceled and continued with event.detail.resume (see Pausing Rendering).
// $(document).on("turbo:before-render", function() {
//   console.log("turbo:before-render")
// })

// :render fires after Turbo renders the page.
// This event fires twice during an application visit to a cached location: once after rendering the cached version,
// and again after rendering the fresh version.
// $(document).on("turbo:render", function() {
//   console.log("turbo:render")
// })

// :frame-render fires right after <turbo-frame> element renders its view.
// The specific <turbo-frame> element is the event target.
// Access the FetchResponse object with event.detail.fetchResponse property.
// $(document).on("turbo:frame-render", function() {
//   console.log("turbo:frame-render")
// })

// $(document).on("turbo:fetch-request-error", function() {
//   console.log("turbo:fetch-request-error")
// })

// :before-stream-render fires before rendering a Turbo Stream page update.
$(document).on("turbo:before-stream-render", function() {
  // console.log("turbo:before-stream-render")

  setTimeout(() => runReloadables(), 1000)
})

// :frame-load fires when <turbo-frame> element is navigated and finishes loading (fires after :frame-render).
// The specific <turbo-frame> element is the event target.
$(document).on("turbo:frame-load", function() {
  // console.log("turbo:frame-load")

  decideFocusedInput()
})

// :load fires once after the initial page load, and again after every Turbo visit.
// Access visit timing metrics with the event.detail.timing object.
$(document).on("turbo:load", function() {
  // console.log("turbo:load")

  loadSentry() // Error and event logging

  runReloadables()

  // TODO: TEMP FIX make sure the datepicker loads only when needed ~ use data-controller='datepicker' instead
  $(".datepicker").datepicker({format: "yyyy-mm-dd", zIndexOffset: 100})
  $(".alert").alert()

  $("#nw_remote_dialog_ok_button").click(function() {
    const container = $("#nw_remote_dialog .modal-body")
    const okCallback = container.data("okCallback")
    const result = okCallback.call(container)
    if (result === true) {
      hideRemoteModal()
    }
  })

  // Enable select2
  $(".multi-select").select2({
    width: "100%"
  })

  decideFocusedInput()
})

/* Bootstrap */

/* Enabling loading of Bootstrap collapsible panels content from remote source */
$(document).on("show.bs.collapse", function (e) {
  // .card .card-collapse
  const panelContent = $(e.target)
  const url = panelContent.data("remote-url")
  if (url != null && panelContent.data("loaded") != true) {
    reloadPanel(panelContent)
  }
})

/* Enabling loading of Bootstrap tabs content from remote source */
$(document).on("shown.bs.tab", function (e) {
  // .nav-tabs li a
  const tabLink = $(e.target)
  const url = tabLink.data("url")

  // Push to history
  const tabId = e.target.href.split("#").pop()
  const browserUrl = new URL(window.location)
  browserUrl.searchParams.set("tab", tabId)
  window.history.pushState({}, "", browserUrl)

  // Push to element
  $("ul.nav.nav-tabs").data("active-tab", tabId)

  if (url != null && tabLink.data("loaded") != true) {
    reloadTab(tabLink.attr("href"))
  }

  showDatatablesFixedHeaders(tabId)
})

/* AJAX */

/* Setup loading indicator */
$(document).ajaxStart(function( event, jqxhr, settings, exception ) {
  showSpinner()
})
$(document).ajaxStop(function( event, jqxhr, settings, exception ) {
  hideSpinner()
})

/* Handle ajax errors */
$(document).ajaxError(function( event, jqxhr, settings, exception ) {
  if ( jqxhr.status == 401 ) {
    alert("Session expired, please login before continuing.")
    //redirect
    window.location.href = "/"
  } else if (jqxhr.status == 422) {
    //this happens on unprocessable entity, validation failing
  } else if (jqxhr.status == 403) {
    alert("Forbidden by role, please ask your lead to allow this action for your account.")
  } else if ( jqxhr.status === 0 && (exception==="canceled" || exception==="abort") ) {
    //do nothing, does not need to be reported
  } else if ( jqxhr.status === 0 && ( exception.length < 1 )) {
    //do nothing, this happens when reloading page while AJAX still working...
    console.log("Ajax ERROR:" + jqxhr.status+"\nException: " + exception + "\n" + "URL: " + settings.url)
  } else {
    alert("Oops! Something went wrong: " + jqxhr.status+"\nException: " + exception + "\n" + "URL: " + settings.url)
  }
})
