import { openRemoteModal } from "../shared/remote_modals"
import { initInPlaceEditor, renderLink } from "../shared/utils"
import { registerOnReadyHandler } from "@nadinewest/common"
import { formatTimestamp } from "../../utils/formatters"

registerOnReadyHandler("messages-incoming_index", () => {

  $("#only_with_action").change(function() {
    table.ajax.reload()
  })

  const table = $("#incoming-sms").DataTable({
    columns: [
      {
        data: "action_required",
        title: "",
        render: renderActionRequiredGlyph
      },
      {
        data: "sent_at",
        title: "Sent At",
        searchable: false,
        render: (data, type, row) => {
          return renderLink("#", formatTimestamp(data), {class: "edit-message-link", "data-message-id": row.id})
        }
      },
      {
        data: "customer",
        title: "Customer",
        sortable: false,
        render: (data, type, row) =>
          renderLink(
            `/customers/${row.customer.id}?tab=sms`,
            row.customer.name
          )
      },
      {
        data: "from_number",
        title: "From Number"
      },
      {
        data: "to_number",
        title: "To Number",
      },
      {
        data: "text",
        title: "Text"
      }
    ],
    order: [[1, "desc"]],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/messages/search",
      data: function(d) {
        d.only_with_action = $("#only_with_action").prop("checked")
      }
    },
    drawCallback: function( settings ) {
      $(this).find(".edit-message-link").click(function() {
        const messageId = $(this).data("message-id")
        openRemoteModal(
          "/messages/"+messageId,
          function(data) {
            initInPlaceEditor(".in-place-editor-node")
          },
          function(data) {
            table.ajax.reload()
            return true // to close window with "Ok" button
          })
        return false
      })
    }
  })

  window.currentDataTable = table

  function renderActionRequiredGlyph(data) {
    return data ? $("<span/>").
      append($("<span class='text-info material-icons'>error</span>")).html() : null
  }

  $("#incoming-sms thead").addClass("table-info")
})
