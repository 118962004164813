import { Controller } from "@hotwired/stimulus"

// Need to submit a form's fields when another form gets submitted?
// set data-form-property: submit-together-form to the form you want to submit together with current form
// connect controller to current form
// -> data: {controller: 'submit-together'}
// -> action: 'formdata->submit-together#submit'
// It will submit the submit-together-form fields along with the current form
export default class extends Controller {
  submit(event) {
    if (event.type === "click") {
      this.handleClickEvent(event)
    } else {
      this.handleFormDataEvent(event)
    }
  }

  handleClickEvent(event) {
    const urlParams = new URLSearchParams(event.target.search)
    const formDataObject = this.processFormFields()

    Object.keys(formDataObject).forEach(key => {
      urlParams.set(key, formDataObject[key])
      urlParams.get(key)
    })

    event.target.search = urlParams
  }

  handleFormDataEvent(event) {
    const formData = event.formData
    const formDataObject = this.processFormFields()

    Object.keys(formDataObject).forEach(key => {
      if (Array.isArray(formDataObject[key])) {
        formDataObject[key].forEach(formValue => {
          formData.append(key, formValue)
        })
      } else {
        formData.set(key, formDataObject[key])
      }
    })
  }

  /**
   * Iterates through all the form fields and assigns the keys and values to the
   * @returns an object representing the fields of the form, with checkboxes represented as arrays.
   */
  processFormFields() {
    const filter = $("[data-form-property='submit-together-form']")
    const formDataObject = {}

    $.each(filter.serializeArray(), function(_index, val) {
      const name = val.name
      const value = val.value

      if (value !== null && value !== "") {
        const isCheckbox = name.endsWith("[]")

        if (isCheckbox) {
          // checkboxes with the same name are added to an array.
          // The first occurrence of a checkbox initializes an array with the value,
          // subsequent occurrences pushes values to the array.
          if (!formDataObject[name]) {
            formDataObject[name] = [value]
          } else {
            formDataObject[name].push(value)
          }
        } else {
          // Regular input fields - text, date, number, etc.
          formDataObject[name] = value
        }
      }
    })

    return formDataObject
  }
}
