import React from "react"
import {Card} from "react-bootstrap"
import PropTypes from "prop-types"

export default class ThirdPartyStatusBase extends React.PureComponent {

  static propTypes = {
    statusTitle: PropTypes.string,
    statusDetailsUrl: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      statusDate: null,
      statusAge: null
    }
  }

  componentDidMount() {
    this.updateStatus()
    this.updateStatusTimerId = setInterval(
      () => this.updateStatus(),
      30 * 1000
    )
    this.updateStatusAgeTimerId = setInterval(
      () => this.updateStatusAge(),
      1000
    )
  }

  componentWillUnmount() {
    clearInterval(this.updateStatusTimerId)
    clearInterval(this.updateStatusAgeTimerId)
  }

  updateStatus() {
    throw "'updateStatus' method must be implemented in ThirdPartyStatusBase subclass"
  }

  updateStatusAge() {
    if (this.state.statusDate != null) {
      const now = new Date()
      this.setState({
        statusAge: Math.ceil((now.getTime() - this.state.statusDate.getTime()) / 1000)
      })
    }
  }

  renderStatusTitle() {
    return (
      <h2>
        {this.props.statusTitle}
      </h2>
    )
  }

  renderStatusAge() {
    return (
      <h5>
        Last status update:&nbsp;
        {this.state.statusAge > 0 ? `${this.state.statusAge} seconds ago` : ""}
      </h5>
    )
  }

  renderStatus() {
    throw "'renderStatus' method must be implemented in ThirdPartyStatusBase subclass"
  }

  renderStatusDetails() {
    return (
      <a
        href={this.props.statusDetailsUrl}
        rel="noopener noreferrer"
        target="_blank"
      >Click here for details</a>
    )
  }

  render() {
    return (
      <Card>
        <Card.Header>
          {this.renderStatusTitle()}
        </Card.Header>
        <Card.Body>
          {this.renderStatus()}
          {this.renderStatusAge()}
          {this.renderStatusDetails()}
        </Card.Body>
      </Card>
    )
  }
}
