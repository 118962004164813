import "./index.scss"
import { renderLink } from "../shared/utils"
import { getCurrentUserId } from "../../utils/common"
import { formatTimestamp, formatTimestampAsDate } from "../../utils/formatters"
import { formatDate, registerOnReadyHandler } from "@nadinewest/common"
import ReactDOM from "react-dom"
import React from "react"
import {IconsAndActionButtons} from "./components/IconsAndActionButtons"
import { doGet } from "@nadinewest/common/javascript/src"

registerOnReadyHandler("shipments-index", () => {
  $("#customer_type").on("change", function () {
    if ($(this).val() != "") {
      $("#customer_tier").prop("disabled", false)
    } else {
      $("#customer_tier").prop("disabled", true)
      $("#customer_tier").val("")
    }
  })

  $("#shipments_filter_form input, #shipments_filter_form select").change(function() {
    $("#shipments-table").DataTable().ajax.reload()
  })

  $("#clear_filters_button").click(function() {
    $("#shipments_filter_form #customer_name").val("")
    $("#shipments_filter_form #customer_email").val("")
    $("#shipments_filter_form #customer_zip").val("")
    $("#shipments_filter_form #customer_phone").val("")
    $("#shipments_filter_form #customer_type").val("")
    $("#shipments_filter_form #assigned_to_me").prop("checked", false)
    $("#shipments_filter_form #charge_failed_counter").val(-1)
    $("#shipments_filter_form #workflow_type").val("")
    $("#shipments_filter_form #not_restocked_in_time").prop("checked", false)
    $("#shipments_filter_form #tag").val("")
    $("#shipments_filter_form #products_sent").val("")
    $("#shipments_filter_form #shipped_date_to").val("")
    $("#shipments_filter_form #shipped_date_from").val("")
    $("#shipments_filter_form #closed_date_from").val("")
    $("#shipments_filter_form #closed_date_to").val("")
    $("#shipments_filter_form input[type='checkbox'][name='top_sizes[]']").prop("checked", true)
    $("#shipments_filter_form input[type='checkbox'][name='bottom_sizes[]']").prop("checked", true)
    $("#shipments_filter_form #assigned_to_user_id").val("")
    $("#shipments_filter_form #has_problem").prop("checked", false)
    $("#shipments-table").DataTable().ajax.reload()
    return false
  })

  const COLUMN_NAMES = {
    created_at: "Created At",
    customer: "Customer",
    top_size:  "Top Size",
    bottom_size: "Bottom Size",
    items_sent_count: "Items Sent",
    items_kept_count: "Items Kept",
    to_style_by_user: "To Style By",
    stylist_assigned_at: "Stylist Assigned At",
    styled_by_user: "Styled By",
    approved_at: "Approved At",
    approved_by_user: "Approved By",
    pick_batch_id: "Pick Batch",
    packed_at: "Packed At",
    packed_by_user: "Packed By",
    last_allowed_return_date: "Last Allowed Return Date",
    to_charge_date: "To Charge Date",
    shipped_at: "Shipped At",
    return_started_at: "Return Started At",
    return_started_by_user: "Return Started By",
    closed_at: "Closed At",
    shipment_status: "Status",
    styling_failures_count: "Styling Failures Count",
    return_completed_at: "Return Completed At",
    return_completed_by_user: "Return Completed By"
  }

  const shipmentsTable = $("#shipments-table")
  const allowApprove = shipmentsTable.data("approve-allowed")
  const allowInspect = shipmentsTable.data("inspect-allowed")
  const allowRestyle = shipmentsTable.data("restyle-allowed")

  const colJSON = shipmentsTable.data("cols")
  const filter = shipmentsTable.data("filter")
  const userId = getCurrentUserId()

  const columns = $.map(colJSON, function(col) {
    const result = {data: col, title: COLUMN_NAMES[col]}

    if (col == "icons") {
      result.width = "8em"
      result.sortable = false
      result.createdCell = function(cell, cellData, rowData) {
        const stylist = rowData.to_style_by_user
        const showStyle = (stylist != null && stylist.id == userId && filter == "to_style")
        ReactDOM.render(
          <IconsAndActionButtons
            shipmentId = {rowData.id}
            showStyleButton = {showStyle}
            showReviewButton = {allowApprove}
            showInspectButton = {allowInspect}
            showRestyleButton = {allowRestyle}
            icons = {rowData.icons}
          />,
          cell
        )
      }
    } else if (col == "created_at") {
      result.render = function(data, type, full) {
        return renderLink(
          `/shipments/${full.id}`,
          formatTimestampAsDate(full.created_at),
          {"class": "shipment-link", "created-at": full.created_at}
        )
      }
    } else if (col == "pick_batch_id") {
      result.render = function(data, type, full) {
        return renderLink(`/pick_batches/${data}`, data)
      }
    } else if (col == "customer") {
      result.render = function(data, type, full) {
        return renderLink(
          `/customers/${full.customer.id}`,
          full.customer.name,
          {"class": "customer-link"}
        )
      }
    } else if (["last_allowed_return_date", "to_charge_date"].includes(col)) {
      result.render = formatDate
    } else if (["approved_at", "inspected_at", "packed_at", "shipped_at",
      "return_started_at", "closed_at", "stylist_assigned_at", "return_completed_at"].includes(col)) {
      result.render = formatTimestamp
    } else if (["to_style_by_user", "styled_by_user", "approved_by_user", "inspected_by_user",
      "packed_by_user", "return_started_by_user", "return_completed_by_user"].includes(col)) {
      result.render = function(data) {
        return data ? data.name : null
      }
    }
    return result
  })

  const table = shipmentsTable.DataTable({
    order: [[ shipmentsTable.data("sort-col-index"), "desc"]],
    columns: columns,
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/shipments/search",
      data: function(d) {
        d.filter = filter
        $.each($("#shipments_filter_form").serializeArray(), function(index, val) {
          const param = val.name
          const value = val.value
          if (value === null || value === "") {
            return
          }

          if (param.endsWith("[]")) {
            // all checkboxes with shared name
            let values = d[param]
            if (values == null) {
              values = [value]
              d[param] = values
            } else {
              values.push(value)
            }
          } else {
            d[param] = value
          }
        })
      }
    },
    createdRow: function(row, data, index) {
      const stylist = data["to_style_by_user"]
      if (stylist != null && stylist.id == userId) {
        $(row).addClass("table-success")
      }
    }
  }).on("xhr.dt", function ( e, settings, json, xhr ) {
    $("#shipments_filter_panel_heading a").text("Current filter: " + json.filterString)
  })

  window.currentDataTable = table

  $("#shipments-table thead").addClass("table-info")
})
