import React from "react"
import PropTypes from "prop-types"
import {RIEInput, RIENumber, RIESelect, RIETextArea, RIEToggle} from "riek"
import RIEDate from "./RIEDate"
import _ from "lodash"
import pluralize from "pluralize"
import {doPatch} from "@nadinewest/common"
import {showErrors} from "src/shared/utils"
import "./InPlaceEditor.scss"

// In-place editor control types
const CONTROL_TYPE_DATE = "date"
const CONTROL_TYPE_NUMBER = "number"
const CONTROL_TYPE_SELECT = "select"
const CONTROL_TYPE_TEXT = "text"
const CONTROL_TYPE_TEXTAREA = "textarea"
const CONTROL_TYPE_TOGGLE = "toggle"

/**
 * Wrapper for riek in-place editor component
 * Renders single editable field
 * Changes will be submitted to server right after editing is finished
 */
export class InPlaceEditor extends React.PureComponent {
  static propTypes = {
    recordType: PropTypes.string.isRequired,
    recordId: PropTypes.number,  // we need either recordId
    updateURL: PropTypes.string, // or updateURL
    property: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.any,
    controlType: PropTypes.oneOf([
      CONTROL_TYPE_DATE,
      CONTROL_TYPE_NUMBER,
      CONTROL_TYPE_SELECT,
      CONTROL_TYPE_TEXT,
      CONTROL_TYPE_TEXTAREA,
      CONTROL_TYPE_TOGGLE
    ]),
    dateFormat: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    options: PropTypes.array,
    rows: PropTypes.number,
    classEditing: PropTypes.string,
    onUpdate: PropTypes.func
  }

  static defaultProps = {
    controlType: CONTROL_TYPE_TEXT,
    readOnly: false,
    onUpdate: () => {
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  /**
   * Callback for riek
   * @param object - object containing changed data in form {changedField: newValue}
   */
  submitFieldChange(object) {
    const objectName = _.snakeCase(this.props.recordType)
    let objectPath = this.props.updateURL
    if (objectPath == null) {
      if (this.props.recordId == null) {
        throw "Nor updateURL nor recordId specified"
      }
      const controllerName = pluralize(objectName)
      objectPath = `/${controllerName}/${this.props.recordId}`
    }
    const objectValue = this.props.controlType === CONTROL_TYPE_SELECT ?
      {[this.props.property]: object[this.props.property].id} : object
    doPatch(
      objectPath,
      {[objectName]: objectValue},
      resp => {
        if (resp.ok) {
          resp.json().then(json => {
            // If response contains changed field value we use it
            const responseObject = json[objectName]
            if (responseObject != null && responseObject.hasOwnProperty(this.props.property)) {
              const value = responseObject[this.props.property]
              // updating value in state to rerender it
              this.setState({value: value})
              this.props.onUpdate()
            }
          }).catch(reason => {
            // response is not a valid json
            const value = objectValue[this.props.property]
            this.setState({value: value})
            this.props.onUpdate()
          })
        } else {
          resp.json().then((json) => {
            showErrors(json["errors"])
            /* forcing re-render to rollback changes in riek editor */
            this.forceUpdate()
          })
        }
      }
    )
  }

  render() {
    if (this.props.readOnly) {
      return this.renderReadOnlyComponent()
    }

    const riekProps = {
      propName: this.props.property,
      value: (this.state.value != null ? this.state.value : ""), // riek doesn't accepts null values
      classEditing: this.props.classEditing,
      className: "text-info clickable property-value",
      classLoading: "text-muted",
      change: this.submitFieldChange.bind(this)
    }

    let editor = null
    const controlType = this.props.controlType
    if (controlType == CONTROL_TYPE_DATE) {
      const dateProps = {}
      if (this.props.dateFormat != null) {
        dateProps.dateFormat = this.props.dateFormat
      }
      editor = (
        <RIEDate
          {...dateProps}
          {...riekProps}
        />
      )
    } else if (controlType == CONTROL_TYPE_NUMBER) {
      const inputProps = {}
      if (this.props.minValue != null) {
        inputProps.min = this.props.minValue
      }
      if (this.props.maxValue != null) {
        inputProps.max = this.props.maxValue
      }
      editor = <RIENumber
        validate={function (value) {
          // standard RIENumber validate doesn't allows empty values
          return (value == null || value.trim() === "") || this.validate()
        }}
        editProps={inputProps}
        {...riekProps}
      />
    } else if (controlType == CONTROL_TYPE_TEXT) {
      editor = <RIEInput
        {...riekProps}
      />
    } else if (controlType == CONTROL_TYPE_SELECT) {
      const selectOptions = this.getSelectOptions()
      const selectValue = selectOptions.find(option =>
        option.id == (this.state.value != null ? this.state.value : "")
      ) || {}
      editor = <RIESelect
        {...riekProps}
        options={selectOptions}
        value={selectValue}
      />
    } else if (controlType == CONTROL_TYPE_TEXTAREA) {
      editor = <RIETextArea
        rows={this.props.rows || 5}
        {...riekProps}
      />
    } else if (controlType == CONTROL_TYPE_TOGGLE) {
      editor = <RIEToggle
        {...riekProps}
      />
    } else {
      throw `Unsupported value of 'controlType' property: ${controlType}`
    }

    return <span className="in-place-editor">
      {editor}
    </span>
  }

  renderReadOnlyComponent() {
    let content
    const controlType = this.props.controlType
    if (controlType === CONTROL_TYPE_TEXTAREA) {
      content = <div className="multiline-text">{this.state.value}</div>
    } else if (controlType === CONTROL_TYPE_SELECT) {
      const selectOptions = this.getSelectOptions()
      const selectValue = selectOptions.find(option =>
        option.id == (this.state.value != null ? this.state.value : "")
      ) || {}
      const selectText = selectValue.text
      content = <span className="property-value">{selectText}</span>
    } else {
      content = <span className="property-value">{this.state.value}</span>
    }

    return <span className="in-place-editor">
      {content}
    </span>
  }

  getSelectOptions() {
    return this.props.options.map(value => {
      // converting values to strings and using empty strings instead of null values
      // for proper searching of selected option in RIESelect component
      if (!Array.isArray(value)) {
        return {id: value != null ? value.toString() : "", text: value}
      } else {
        if (value.length != 2) {
          throw `Unsupported value of 'options' property: ${this.props.options}`
        }
        return {id: value[1] != null ? value[1].toString() : "", text: value[0]}
      }
    })
  }
}
