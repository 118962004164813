import {openSimpleFormRemoteModal} from "src/shared/remote_modals"
import { registerOnReadyHandler, formatMoney } from "@nadinewest/common"
import { renderLink } from "../../shared/utils"

registerOnReadyHandler("admin-gift_cards-index", () => {
  const giftCardsTableNode = $("#gift-cards-table")

  const dataTable = giftCardsTableNode.DataTable({
    data: giftCardsTableNode.data("gift-cards"),
    columns: [
      {
        title: "Description",
        data: "description",
        render: (data, type, row) =>
          renderLink("#", row.description, {class: "edit-gift-card-link", "data-gift-card-id": row.id})
      },
      {
        title: "Card amount",
        data: "card_amount",
        render: formatMoney
      },
      {
        title: "Card Price",
        data: "price",
        render: formatMoney
      },
      {
        title: "Variation",
        data: "variation_id",
        render: (data, type, row) =>
          renderLink(
            `/variations/${row.variation_id}`,
            row.variation_id,
            { target: "_blank" }
          )
      },
      {
        title: "Quantity",
        data: "quantity"
      },
      {
        title: "Disabled",
        data: "disabled",
        render: renderEnabledGlyph
      }
    ],
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/admin/gift_cards/search"
    },
    drawCallback: function(settings) {
      $(this).find(".edit-gift-card-link").click(function() {
        const giftCardId = $(this).data("gift-card-id")
        openSimpleFormRemoteModal(
          "/admin/gift_cards/" + giftCardId + "/edit",
          null,
          null,
          function(data) {
            dataTable.ajax.reload()
          }
        )
      })
    }
  })
  window.currentDataTable = dataTable

  $("#new-gift-card-button").click(function() {
    openSimpleFormRemoteModal(
      "/admin/gift_cards/new",
      null,
      null,
      function(data) {
        dataTable.ajax.reload()
      }
    )
  })

  function renderEnabledGlyph(data) {
    return data ? $("<span/>").append($("<span class='material-icons'>done</span>")).html() : null
  }

  $("#gift-cards-table thead").addClass("table-info")
})
