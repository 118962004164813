import "@nadinewest/common/stylesheets/colors-linear-gradients.scss"
import React from "react"
import ReactDOM from "react-dom"
import {formatMoney, formatPercent} from "@nadinewest/common"
import {SizeModifierEditor} from "components/products/SizeModifierEditor"
import { registerOnReadyHandler } from "@nadinewest/common"
import { adjustColour } from "../../utils/common"

registerOnReadyHandler("products-show", () => {
  const sizeModifier = $("#change-size-modifier")
  if (sizeModifier.get(0)) {
    ReactDOM.render(
      <SizeModifierEditor
        productId={sizeModifier.data("product-id")}
        sizeModifier={sizeModifier.data("size-modifier")}
        editAllowed={sizeModifier.data("edit-allowed")}
      />,
      sizeModifier.get(0)
    )
  }
  $("#stats").on("NW:ContentLoaded", onStatsTabLoaded)
  $("#style-tab").on("NW:ContentLoaded", onStyleTabLoaded)
})

const onStatsTabLoaded = function() {

  const productStatsTable = $("#product-stats")

  const columns = [
    {
      data: "price",
      title: "Price",
      render: function(data, type, full, meta ) {
        return data == "Total" ? data : formatMoney(data)
      }
    },
    {
      data: "flavor",
      title: "Flavor",
    },
    {
      data: "size",
      title: "Size"
    },
    {
      data: "keep_rate",
      title: "Keep Rate",
      render: formatPercent
    },
    {
      data: "sent_count",
      title: "Sent"
    },
    {
      data: "kept_count",
      title: "Kept"
    }
  ]

  if (productStatsTable.data("is-see-cost-and-stats")) {
    columns.push({ data: "total_profit", title: "Total Profit", render: formatMoney })
    columns.push({ data: "avg_profit", title: "Average Profit", render: formatMoney })
    columns.push({ data: "expected_profit", title: "Expected Profit", render: formatMoney })
  }

  const table = productStatsTable.DataTable({
    serverSide: false,
    serverMethod: "get",
    ajax: {
      url: `/products/${productStatsTable.data("product-id")}/stats`,
      data: function(d) {
        d.by_flavors = $("#by_flavors").prop("checked")
        d.by_sizes = $("#by_sizes").prop("checked")
      }
    },
    paginate: false,
    sort: false,
    filter: false,
    info: false,
    columns: columns,
    drawCallback: function(settings) {
      if (table != null) {
        table.column(1).visible($("#by_flavors").prop("checked"))
        table.column(2).visible($("#by_sizes").prop("checked") == true)
      }
    },
    createdRow: function(row, data, index) {
      const price = data["price"]
      if (price == productStatsTable.data("suggested-price")) {
        $(row).addClass("text-info")
      }
    }
  })
  window.dataTables["stats"] = table


  $("#by_flavors, #by_sizes").change(function() {
    table.ajax.reload()
  })

  $("#product-stats thead").addClass("table-info")

  $("#product-st thead").addClass("table-info")
}

const onStyleTabLoaded = function() {
  const revealedAreaNone = $("#product_revealed_areas_none")
  const revealedAreasOther =
    $('input[type="checkbox"][name="product[revealed_areas][]"]:not(#product_revealed_areas_none)')

  revealedAreaNone.change(function() {
    if ($(this).prop("checked")) {
      revealedAreasOther.prop("checked", false)
    }
  })
  revealedAreasOther.change(function() {
    if ($(this).prop("checked")) {
      revealedAreaNone.prop("checked", false)
    }
  })

  const extraAttributesNone = $("#product_extra_attributes_none")
  const extraAttributesOther =
    $('input[type="checkbox"][name="product[extra_attributes][]"]:not(#product_extra_attributes_none)')

  extraAttributesNone.change(function() {
    if ($(this).prop("checked")) {
      extraAttributesOther.prop("checked", false)
    }
  })
  extraAttributesOther.change(function() {
    if ($(this).prop("checked")) {
      extraAttributesNone.prop("checked", false)
    }
  })

  // Clicking the table cell selects the inner checkbox
  $(".attribute-checkbox-cell").click(function(e) {
    const checkbox = $(this).find("input:checkbox").get(0)
    if (e.target != checkbox) {
      checkbox.checked = !checkbox.checked
      checkbox.dispatchEvent(new Event("change"))
    }
  })

  $(".attribute-checkbox-title.js-toggle-contrasting-color").each(function(index) {
    const attribute = $(this).data("attribute")
    adjustColour(attribute, $(this))
  })
}
