import React from "react"
import ReactDOM from "react-dom"
import { openSimpleFormRemoteModal } from "../shared/remote_modals"
import { openNewProductDialog } from "../products/new"
import { openVariationsSearchDialog } from "../variations/search_dialog"
import { SkuWithCategory } from "../../components/products/SkuWithCategory"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("orders-show", () => {
  const itemsTableNode = $("#js-order")
  const orderId = itemsTableNode.data("order-id")
  const vendorId = itemsTableNode.data("vendor-id")

  // Snippet used to add quantity field to product creation form and variations search dialog
  const quantityToOrderSnippet = "<div class='row form-group'>\
   <label class='col-form-label col-sm-3'>Quantity to order (per color/size)</label>\
   <div class='col-sm-9'>\
     <input class='form-control'\
            id='quantity' type='number' name='quantity' value='10' required='true' min='1' max='1000'/>\
   </div>\
  </div>"

  const addItemsToOrder = function (variationIds, quantity) {
    const form = $("#add_new_product_order_items_form")
    form.find("input[name=quantity]").val(quantity)
    for (let i = 0; i < variationIds.length; ++i) {
      form.append("<input type=\"hidden\" name=\"variation_id[]\" value=\"" + variationIds[i] + "\"/>")
    }
    form.submit()
  }

  $("#create_new_product").click(function () {
    let quantity = null
    let variationIds = null
    openNewProductDialog(
      vendorId,
      function () {
        // afterLoadCallback
        /* Adding new field for quantity to order to new product form */
        $(quantityToOrderSnippet).insertAfter("#new_product div.product_name")
      },
      function (product, status, xhr) {
        // afterCreateCallback
        /* After product is created creating new items using all created variations */
        if (product) { // When called from openSimpleFormRemoteModal product is null
          quantity = $(this).find("input[name=quantity]").val()
          variationIds = product.variationIds
        }
      },
      function (data, status, xhr) {
        // afterStyleFilledCallback
        addItemsToOrder(variationIds, quantity)
      }
    )
  })

  $("#add_existing_product").click(function () {
    openVariationsSearchDialog(
      {
        for_order_id: orderId,
        multiselect: true
      },
      function () {
        /* Adding new field for quantity to order to new product form */
        this.append(quantityToOrderSnippet)
      },
      function (variationIds) {
        const quantity = $(this).find("input[name=quantity]").val()
        addItemsToOrder(variationIds, quantity)
        return true
      }
    )
  })

  $("#add-file-button, #replace-file-button").click(function () {
    const input = $("#invoice")
    input.val(null)
    input.trigger("click")
  })

  $("#invoice").change(function () {
    if ($("#invoice").val() == null) {
      return
    }
    this.form.submit()
  })
})
