import React from "react"
import ReactDOM from "react-dom"
import {InPlaceEditor} from "components/utils/InPlaceEditor"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("admin-settings-show", () => {
  const activeTabId = $("#settings-tabs").data("active-tab")
  $(`li a[href="#${activeTabId}"]`).tab("show")

  $(".select-parameter").each(function(index) {
    const elem = $(this)
    ReactDOM.render(
      <InPlaceEditor
        controlType="select"
        property="value"
        recordType="system_parameter"
        value={elem.data("value")}
        options={elem.data("options")}
        updateURL={elem.data("url")}
      />,
      elem.get(0)
    )
  })
})
