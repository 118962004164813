import "./dashboard.scss"
import "./place_1.png"
import "./place_2.png"
import "./place_3.png"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler(["dashboard-inventory", "dashboard-overview", "dashboard-picking",
  "dashboard-return","dashboard-ship", "dashboard-styling"], () => {
  const navbarHeight = $(".navbar").height()
  const pageHeaderHeight = $(".page-header").height()
  const h = $(window).height() - navbarHeight - pageHeaderHeight

  $(".dashboard-section").css({ height: h + "px" })

  $("#packed_by_teams_chart").css({ height: h/2 + "px"})

  setInterval(
    function() {
      window.location.reload(true)
    },
    300000
  )
})