import React from "react"
import ReactDOM from "react-dom"
import {InPlaceEditor} from "components/utils/InPlaceEditor"

/**
 * Renders url with given title as a link
 * @param url url of link to be rendered
 * @param text link text
 * @param htmlLinkAttrs optional additional html attributes to be added to <a> tag (e.g. class etc)
 * @returns html representing link
 */
export function renderLink(url, text, htmlLinkAttrs = {}) {
  htmlLinkAttrs.href = url
  return $("<span/>").append($("<a/>", htmlLinkAttrs).text(text)).html()
}

/**
 * Renders product image
 * @param url url of the image to be rendered
 * @param size of image
 * @param title image title
 * @returns html representing image
 */
export function renderProductImage(url, size, title) {
  const div = $("<div/>", { class: `product-image product-image-${size}` })
  const image = $("<img/>", {
    src: url,
    alt: title,
    title: title
  })

  return $("<div/>").append(div.append(image)).html()
}

/**
 * Renders product's SKU and category name.
 * @param {string} sku - SKU of the product.
 * @param {string} categoryName - Name of the category.
 * @param {number|null} productId - Product ID, if available.
 * @param {string|null} tab - Query parameter for the URL, if needed.
 * @returns {string} HTML representing the SKU and category name.
 */
export function renderSkuWithCategory(sku, categoryName, productId, tab) {
  let skuElement
  if (productId != null) {
    const url = `/products/${productId}${tab ? `?tab=${tab}` : ""}`
    skuElement = $("<a/>", { href: url, text: sku })
  } else {
    skuElement = $("<span/>").text(sku)
  }

  return $("<div/>").
    append(skuElement).
    append($("<div/>", { class: "text-muted" }).text(categoryName)).
    html()
}

/**
 * Intended to collect values of selected checkboxes. But can be used for any elements responding to val() method.
 * @param selector of html elements to collect values from
 * @return array of values matching given jquery selector
 */
export function selectedValues(selector) {
  const result = []
  $(selector).each(function(index) {
    result.push($(this).val())
  })
  return result
}

/**
 * Function used to initialize InPlaceEditor
 * Intended to be used on document:ready and for initializing dynamically generated content
 * @param selector selector matching all items to be initialized
 */
export function initInPlaceEditor(selector) {
  $(selector).each(function(index, element) {
    ReactDOM.render(
      <InPlaceEditor
        classEditing={$(element).data("in-place-editor-class-editing")}
        controlType={$(element).data("in-place-editor-control-type")}
        options={$(element).data("in-place-editor-options")}
        property={$(element).data("in-place-editor-property")}
        readOnly={$(element).data("in-place-editor-read-only")}
        recordId={$(element).data("in-place-editor-record-id")}
        recordType={$(element).data("in-place-editor-record-type")}
        rows={$(element).data("in-place-editor-rows")}
        value={$(element).data("in-place-editor-value")}
        updateURL={$(element).data("in-place-editor-update-url")}
      />,
      element
    )
  })
}

/**
 * This function is used to show validation errors returned by ajax callbacks
 * @param errors array of string, representing error messages
 */
export function showErrors(errors) {
  alert(errors.join("\n"))
}

/**
 * This method can be used to reload content of dynamically loaded tab, e.g. after some ajax request modifiyng content
 * of the tab
 * @param tabId identifier of tab to be reloaded with preceding '#' (value of href attribute of nav-tabs 'a' element).
 */
export function reloadTab(tabId) {
  const tabHeader = $('.nav-tabs li a[href="' + tabId + '"]')
  const url = tabHeader.data("url")
  const tab = $(tabId)
  tab.html("<p>Loading...</p>")
  tab.load(
    url,
    function(response, status, xhr ) {
      if ( status == "error" ) {
        tab.html("<span class='text-danger'>Failed to load tab content</span>")
      } else {
        /*
         * Initializing additional standard controls on loaded tab
         * TODO: remove this, all custom initialization should be done inside NW:ContentLoaded callback
         */
        initInPlaceEditor(tabId + " .in-place-editor-node")
        tab.find(".datepicker").datepicker({format: "yyyy-mm-dd"})
        tab.trigger("NW:ContentLoaded")
        tabHeader.data("loaded", true)
      }
    }
  )
}

/**
 * This method can be used to reload content of dynamically loaded panel, e.g. after some ajax request modifiyng content
 * of the panel
 * @param panelContent jquery object representing panel content element to be reloaded
 */
export function reloadPanel(panelContent) {
  const url = panelContent.data("remote-url")
  if (url != null) {
    const body = panelContent.find(".card-body")
    body.html("<p>Loading...</p>")
    body.load(
      url,
      function(response, status, xhr ) {
        if ( status == "error" ) {
          $(this).html("<span class='text-danger'>Failed to load panel content</span>")
        } else {
          /* Initializing additional controls on loaded panel */
          $(this).find(".datepicker").datepicker({format: "yyyy-mm-dd"})
          initInPlaceEditor("#" + panelContent.attr("id") + " .in-place-editor-node")
          body.trigger("NW:ContentLoaded")
          panelContent.data("loaded", true)
        }
      }
    )
  }
}

/**
 * This method is used to encode object keys and values into  URL-encoded format
 * @param formData an object representing the query params to be appended to the url
 * @return a string representing urlencoded query parameters
 */
export function encodeQueryData(formData) {
  const ret = []
  for (const key in formData) {
    if(formData[key]) {
      if (Array.isArray(formData[key])) {
        formData[key].forEach(value => {
          ret.push(key + "=" + encodeURIComponent(value))
        })
      } else {
        ret.push(key + "=" + encodeURIComponent(formData[key]))
      }
    }
  }

  return ret.join("&")
}
