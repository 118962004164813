import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $("#product_brand_id").select2({
      tags: true,
      tokenSeparators: [","],
      width: "100%",
    })
  }
}
