import { Controller } from "@hotwired/stimulus"

// Editable in place
export default class extends Controller {
  static targets = [ "presenting", "editing", "input", "form"]
  static values = { as: String, editable: String }

  connect() {
    const that = this

    // Datepicker setup
    if (this.asValue === "date") {
      $(this.inputTarget).datepicker({format: "yyyy-mm-dd", autoclose: true})
      $(this.inputTarget).datepicker().on("hide", function(event) {
        that.save(event)
      })
    }

    // Clicking outside of editable
    $(document).on("click", function(event) {
      if ($(that.presentingTarget).css.visibility === "visible") {
        // do nothing, already presenting
        return
      }

      if ($(event.target).hasClass("editable")) {
        // do nothing, clicking inside the editable input
      } else {
        // hide editing, clicking outside the editable input
        that.present(event)
      }
    })

    /**
     * If the user clicks enter while editing the form, this event will be triggered
     * The form will be submitted. Handle it by hiding the form and showing the value.
     * This prevents the form from being submitted twice - #save will check for a visible form
      * */ 
    $(this.editingTarget).on("submit", function(event) {
      that.present(event)
    })
  }

  disconnect() {
    if (this.asValue === "date") {
      $(this.inputTarget).datepicker("destroy")
    }
  }

  // Show editing
  edit(event) {
    event.stopPropagation()
    event.preventDefault()

    if (this.editableValue === "true") {
      if (this.asValue === "toggle") {
        this.toggle(event)
      } else {
        $(this.editingTarget).show()
        $(this.inputTarget).select()
        $(this.presentingTarget).hide()
      }
    }
  }

  // Save & show presenting
  save(event) {
    const isEditingTargetVisible = this.editingTarget.style.display !== "none"
    if (this.editableValue === "true" && isEditingTargetVisible) {
      this.formTarget.requestSubmit()
    }
  }

  // Show presenting
  present(event) {
    $(this.editingTarget).hide()
    $(this.presentingTarget).show()
  }

  toggle(event) {
    if (this.editableValue === "true") {
      const inputTarget = $(this.inputTarget)
      inputTarget.prop("checked", !inputTarget.prop("checked"))

      this.formTarget.requestSubmit()
    }
  }
}
