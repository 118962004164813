import { Controller } from "@hotwired/stimulus"
import {decideFocusedInput} from "../../utils/common"

// Scrolls into view and highlights the element.
export default class extends Controller {
  static values = {
    bgColor: { type: String, default: "#fff700" },
    color: { type: String, default: "blue" },
    interval: { type: Number, default: 3 }, // seconds
    clear: Boolean, // clear animation if set to other than "0" or "false"
    decideFocus: { type: Boolean, default: true } // decide focused input if set to other than "0" or "false"
  }

  connect() {
    this.element.scrollIntoView({ behavior: "smooth", block: "center"})

    // Save current background color and color
    const defaultBG = this.element.style.backgroundColor
    const defaultColor = this.element.style.color
    const defaultTransition = this.element.style.transition

    // Animate background color and color
    this.element.style.transition = "background " + this.intervalValue + "s"
    this.element.style.backgroundColor = this.bgColorValue
    this.element.style.color = this.colorValue

    // Restore and clear the animation:
    if (this.clearValue === true) {
      const thisElement = this.element
      setTimeout(function() {
        thisElement.style.backgroundColor = defaultBG
        thisElement.style.transition = defaultTransition
        thisElement.style.color = defaultColor
      }, (this.intervalValue * 1000))
    }

    // Focus on relevant input field, right after animation
    if (this.decideFocusValue === true) {
      setTimeout(function() {
        decideFocusedInput()
      }, (this.intervalValue * 1000) + 200)
    }
  }
}
