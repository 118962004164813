import { renderLink } from "../shared/utils"
import { formatTimestamp } from "../../utils/formatters"
import { registerOnReadyHandler } from "@nadinewest/common"
import ReactDOM from "react-dom"
import React from "react"

registerOnReadyHandler("pick_batches-index", () => {
  const table = $("#pick-batches-table").DataTable({
    order: [[1, "desc"]],
    columns: [
      { data: "id", title: "Id" },
      {
        data: "created_at",
        title: "Created At",
        render: (data, type, row) =>
          renderLink(
            `/pick_batches/${row.id}`,
            formatTimestamp(row.created_at)
          )
      },
      { data: "created_by_user.name", title: "Created By", sortable: false },
      { data: "status", title: "Status" },
      { data: "picked_by_user", title: "Picked By", sortable: false },
      { data: "shipments_count", title: "Shipments Count" }
    ],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/pick_batches/search",
      data: function(d) {
        if ($("#completed").prop("checked")) {
          d.completed = 1
        }
        d.status_filter = $("#status_filter").val()
        return d
      }
    }
  })

  window.currentDataTable = table

  $("#completed, #status_filter").change(function(event) {
    $("#pick-batches-table").DataTable().ajax.reload()
  })

  $("#open_batch").click(function() {
    const batchId = $("#batch_id").val()
    if (batchId) {
      window.open(`/pick_batches/${batchId}`)
    } else {
      alert("Please enter Batch Id")
    }
    return false
  })

  $("#job-panel").on("updated-background-job-results", function(event, worker) {
    event.stopImmediatePropagation()

    // refresh pick batches table data
    table.ajax.reload()
  })

  $("#job-panel").on("finished-updating-background-job-results", function(event, worker) {
    event.stopImmediatePropagation()

    // refresh pick batches table data
    table.ajax.reload()

    // enable create button depending on last page of background jobs is complete
    const button = $("input.js-create-pick-batch-button")
    button.removeClass("disabled")
    button.prop("disabled", false)
  })

  $("form.js-create-pick-batch-form").on("submit", function(event) {
    // disable create button on submit
    const button = $("input.js-create-pick-batch-button")

    if (button.hasClass("disabled")) {
      event.preventDefault()
      event.stopImmediatePropagation()
    } else {
      button.addClass("disabled")
      button.prop("disabled", true)
    }
  })

  $("form.js-create-pick-batch-form").bind("ajax:success", function(event) {
    const data = event.detail[0]
    if (data.error) {
      alert(data.error)
    } else {
      $("#job-panel").trigger("run-background-job-results", ["CreatePickBatchWorker"])
      $("#job-panel").collapse("show")
    }
  })

  $("form.js-create-pick-batch-form").on("ajax:error", function(event) {
    $("#job-panel").trigger("stop-background-job-results", ["CreatePickBatchWorker"])
    window.log("Scheduling pick batch request failed.")
    alert("Whoops, unexpected error while scheduling. We have been notified, please try later.")
  })


  $("#pick-batches-table thead").addClass("table-info")
})
