import { openSimpleFormRemoteModal } from "src/shared/remote_modals"
import { registerOnReadyHandler, formatMoney, formatDate } from "@nadinewest/common"
import { renderLink } from "../../shared/utils"

registerOnReadyHandler("admin-discount_codes-index", () => {
  const discountCodesTableNode = $("#discount-codes-table")

  const dataTable = discountCodesTableNode.DataTable({
    data: discountCodesTableNode.data("discount-codes"),
    columns: [
      {
        title: "Code",
        data: "code",
        render: (data, type, row) =>
          renderLink("#", row.code, {
            class: "edit-discount-code-link",
            "data-discount-code-id": row.id
          })
      },
      {
        title: "Description",
        data: "description"
      },
      {
        title: "Amount",
        data: "amount",
        render: formatMoney
      },
      {
        title: "Repeat count",
        data: "repeat_count"
      },
      {
        title: "Gift Card",
        data: "gift_card_description"
      },
      {
        title: "No upfront fee",
        data: "no_upfront_fee",
        render: renderEnabledGlyph
      },
      {
        title: "Partner",
        data: "partner",
        render: renderEnabledGlyph
      },
      {
        title: "Kept all for Discount",
        data: "kept_all_condition",
        render: renderEnabledGlyph
      },
      {
        title: "Expired Date",
        data: "expires_at",
        render: formatDate
      },
      {
        title: "Price",
        data: "price",
        render: formatMoney
      }
    ],
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/admin/discount_codes/search"
    },
    drawCallback: function(settings) {
      $(this).find(".edit-discount-code-link").click(function() {
        const discountCodesId = $(this).data("discount-code-id")
        openSimpleFormRemoteModal(
          "/admin/discount_codes/" + discountCodesId + "/edit",
          function() {
            this.find(".datepicker").datepicker({format: "yyyy-mm-dd"})
          },
          null,
          function() {
            window.location.href = "/admin/discount_codes"
          }
        )
      })
    }
  })
  window.currentDataTable = dataTable

  $("#new-discount-code-button").click(function() {
    openSimpleFormRemoteModal(
      "/admin/discount_codes/new",
      function() {
        this.find(".datepicker").datepicker({format: "yyyy-mm-dd"})
      },
      null,
      function(data) {
        dataTable.ajax.reload()
      }
    )
  })

  function renderEnabledGlyph(data) {
    return data ? $("<span/>").append($("<span class='material-icons'>done</span>")).html() : null
  }

  $("#discount-codes-table thead").addClass("table-info")
})
