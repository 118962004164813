/* global Product */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "urlFields"];

  connect() {
    $(this.selectTarget).select2({
      tags: true,
      width: "100%",
      language: { noResults: () => null }, // hide no results message
      matcher: () => null, // hide options
      createTag: ({ term }) => (this.isValidFlavor(term) ?
        { id: term, text: term, newOption: true } : null
      )
    }).on("select2:select", (e) => this.handleSelect(e)).
      on("select2:unselect", (e) => this.handleUnselect(e))

    this.updateUrlFields()
  }

  handleSelect(e) {
    const selectedFlavors = $(e.currentTarget).val()
    this.updateUrlFields(selectedFlavors)
  }

  handleUnselect(e) {
    const selectedFlavors = $(e.currentTarget).val() || []
    this.updateUrlFields(selectedFlavors)
  }

  updateUrlFields(flavors = []) {
    if (flavors.length === 0) {
      this.urlFieldsTarget.innerHTML = `
        <div class="form-group">
          <label class="col-form-label">Image URL</label>
          <input type="url" class="form-control" name="image_url">
        </div>`
    } else {
      this.urlFieldsTarget.innerHTML = flavors.map(flavor =>
        `<div class="form-group">
          <label class="col-form-label">Image URL (${flavor})</label>
          <input type="url" class="form-control" name="image_url_${flavor}">
        </div>`
      ).join("")
    }
  }

  isValidFlavor(flavor) {
    return flavor.match(Product.PRODUCT_FLAVOR_NAME_PATTERN) != null &&
           flavor.length <= Product.PRODUCT_FLAVOR_NAME_MAX_LENGTH
  }
}
