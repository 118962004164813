import { application } from "../application"
import ReadyController from "../common/ready_controller.js"
import DatepickerController from "../common/datepicker_controller.js"
import FocusableController from "./focusable_controller.js"
import NotificationController from "./notification_controller.js"
import SubmittableController from "./submittable_controller.js"
import TabbedController from "./tabbed_controller.js"
import EditableController from "./editable_controller.js"
import ChangeLocationQuantityFormController from "./change_location_quantity_form_controller.js"
import SubmitTogetherController from "./submit_together_controller.js"
import EnableSubmitWhenRequiredFieldsPopulatedController
  from "./enable_submit_when_required_fields_populated_controller"
import LocationsSuggestionController from "./locations_suggestion_controller.js"
import InventoryMovementController from "./inventory_movement_controller.js"
import OrdersCsvController from "./orders_csv_controller.js"
import AssignStylistsFormController from "./assign_stylists_form_controller.js"
import ReloadPageMenuController from "./reload_page_menu_controller.js"
import ReloadCurrentDatatableController from "./reload_current_datatable_controller.js"
import ScannerController from "./scanner_controller.js"
import ReadTextController from "./read_text_controller.js"
import ProductFlavorsController from "./product_flavors_controller.js"
import SelectOptionsController from "./select_options_controller.js"
import BrandSelectorController from "./brand_selector_controller.js"
import BrandsController from "./brands_controller.js"

application.register("datepicker", DatepickerController)
application.register("focusable", FocusableController)
application.register("notification", NotificationController)
application.register("ready", ReadyController)
application.register("submittable", SubmittableController)
application.register("tabbed", TabbedController)
application.register("editable", EditableController)
application.register("change-location-quantity-form", ChangeLocationQuantityFormController)
application.register("submit-together", SubmitTogetherController)
application.register("enable-submit-when-required-fields-populated", EnableSubmitWhenRequiredFieldsPopulatedController)
application.register("locations-suggestion", LocationsSuggestionController)
application.register("inventory-movement", InventoryMovementController)
application.register("orders-csv", OrdersCsvController)
application.register("assign-stylists-form", AssignStylistsFormController)
application.register("reload-page-menu", ReloadPageMenuController)
application.register("reload-current-datatable", ReloadCurrentDatatableController)
application.register("scanner", ScannerController)
application.register("read-text", ReadTextController)
application.register("product-flavors", ProductFlavorsController)
application.register("select-options", SelectOptionsController)
application.register("brand-selector", BrandSelectorController)
application.register("brands", BrandsController)
