import { Controller } from "@hotwired/stimulus"

// Controller for the Change Location quantity form, to disable/enable the submit button
export default class extends Controller {
  static targets = [
    "quantityField",
    "quantityDeltaField",
    "quantity",
    "quantityDelta",
    "toggleDeltaInput",
    "toggleExactInput",
    "name",
    "form",
    "button",
    "hintOne",
    "hintTwo"
  ]

  connect() {
    this.deltaInput = false // by default show form for setting exact quantity, instead of delta quantity
    this.decideSubmitBtnStatus()
  }

  submit(event) {
    event.preventDefault()

    if (this.deltaInput) {
      // showing delta input
      // do not submit exact
      $(this.quantityTarget).attr("disabled", "disabled")
    } else {
      // showing exact input
      // do not submit delta
      $(this.quantityDeltaTarget).attr("disabled", "disabled")
    }

    this.formTarget.requestSubmit()
  }

  nameChanged(event) {
    const selectedName = event.target.value

    if (selectedName === "damaged_item") {
      $(this.hintOneTarget).hide()
      $(this.hintTwoTarget).show()
      // show delta quantity input for damaged item
      this.showDelta()
      // only delta input is available for damaged item
      this.hideToggle()
    } else {
      $(this.hintOneTarget).show()
      $(this.hintTwoTarget).hide()
      // both exact and delta are available inputs
      this.showToggle()
    }

    this.decideSubmitBtnStatus()
  }

  toggleInput(event) {
    event.preventDefault()
    if (this.deltaInput) {
      // show exact input
      this.showExact()
    } else {
      // show delta input
      this.showDelta()
    }
  }

  quantityFocus(event) {
    event.target.select()
    this.decideSubmitBtnStatus()
  }

  quantityInput(event) {
    this.decideSubmitBtnStatus()
  }

  decideSubmitBtnStatus() {
    const quantityExact = this.quantityTarget.value
    const quantityDelta = this.quantityDeltaTarget.value
    const selectedName = this.nameTarget.querySelector("input[type='radio']:checked")
    const submitBtn = this.buttonTarget

    if (this.deltaInput) {
      submitBtn.disabled = quantityDelta == null || quantityDelta == 0 || selectedName == null
    } else {
      submitBtn.disabled = quantityExact == null || quantityExact < 0 || selectedName == null
    }
  }

  showDelta() {
    this.deltaInput = true
    $(this.quantityDeltaFieldTarget).show()
    $(this.quantityFieldTarget).hide()
    $(this.toggleExactInputTarget).show()
    $(this.toggleDeltaInputTarget).hide()
  }

  showExact() {
    this.deltaInput = false
    $(this.quantityDeltaFieldTarget).hide()
    $(this.quantityFieldTarget).show()
    $(this.toggleExactInputTarget).hide()
    $(this.toggleDeltaInputTarget).show()
  }

  hideToggle() {
    $(this.toggleExactInputTarget).hide()
    $(this.toggleDeltaInputTarget).hide()
  }

  showToggle() {
    if (this.deltaInput) {
      $(this.toggleExactInputTarget).show()
      $(this.toggleDeltaInputTarget).hide()
    } else {
      $(this.toggleExactInputTarget).hide()
      $(this.toggleDeltaInputTarget).show()
    }
  }
}
