import {registerOnReadyHandler} from "@nadinewest/common"

registerOnReadyHandler("inventory_reports-ordering_calculator_settings", () => {
  const calculateTotals = function() {
    let total = 0
    $("input.range").each(function(index) {
      total += parseFloat($(this).val())
    })
    total = total.toFixed(2)
    $("#avg-items-per-shipment").html(total)
  }

  $("input.range").change(function(event) {
    $(this).parent().find("label").html($(this).val())
    calculateTotals()
  })

  // To initialize initial total value
  calculateTotals()

  $("input#limit_shipments").change(function(event) {
    $("input#shipments_per_week").prop("disabled", !$(this).prop("checked"))
  })
  $("input#limit_shipments").change()
})
