import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["more", "less"]

  more (event) {
    this.lessTarget.style.display = "block"
    this.moreTarget.style.display = "none"
  }

  less (event) {
    this.lessTarget.style.display = "none"
    this.moreTarget.style.display = "block"
  }
}
