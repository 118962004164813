import React from "react"
import ReactDOM from "react-dom"
import {openVariationsSearchDialog} from "src/variations/search_dialog"
import {doPost} from "@nadinewest/common"
import {InPlaceEditor} from "components/utils/InPlaceEditor"
import {initInPlaceEditor} from "src/shared/utils"
import { registerOnReadyHandler } from "@nadinewest/common"
import {Col, Row} from "react-bootstrap"

registerOnReadyHandler("shipments-show", () => {
  const shipmentProductsTable = $("#shipment-products-table")
  const shipmentVariationIds = shipmentProductsTable.data("shipment-variation-ids")
  const sentItemCount = shipmentVariationIds.length

  const tab = $("#shipment-tabs").data("active-tab")
  $(`li a[href="#${tab}"]`).tab("show")

  $("#attachments").on("NW:ContentLoaded", onAttachmentsTabLoaded)

  $("#add-item-button").click(function() {
    const shipmentId = $("#add-item-button").data("shipment-id")
    openVariationsSearchDialog({ignored_ids: shipmentVariationIds}, null, function(variationIds) {
      doPost(
        `/shipments/${shipmentId}/items`,
        {variation_id: variationIds[0]},
        (resp) => {
          if (resp.ok) {
            // Products rendered statically in view, so reload page, after adding
            window.location.href = location.pathname + "?tab=products"
          } else {
            alert("Error on adding shipment item!")
          }
        }
      )
    })
  })

  // CS tab
  const form = $("#helpscout_form")
  form.find("select#subject").change(function() {
    const item = $(this).find("option:selected")
    if (item != null) {
      form.find("textarea#note").val(item.data("note"))
    }
  })

  $("#close_stolen_button").click(function() {
    if (!confirm("Do you really want to close this shipment as stolen and mark pending payment as uncollected?")) {
      return
    }

    if (confirm("Inactivate customer as well?")) {
      $("#inactivate_customer").val("true")
    } else {
      $("#inactivate_customer").val("false")
    }
    $("#close_stolen_form").submit()
  })

  initInPlaceEditor(".in-place-editor-node")
})

const onAttachmentsTabLoaded = function() {
  $("#add-file-button").click(function() {
    const input = $("#shipment_attachment_attachment")
    input.val(null)
    input.trigger("click")
  })

  $("#shipment_attachment_attachment").change(function() {
    if ($("#shipment_attachment_attachment").val() == null) {
      return
    }
    this.form.submit()
  })
}
