import {renderLink} from "../../shared/utils"
import {openSimpleFormRemoteModal} from "src/shared/remote_modals"
import {registerOnReadyHandler} from "@nadinewest/common"

registerOnReadyHandler("admin-print_stations-index", () => {
  const filter = $("#print_stations_filter_panel_content_body")

  const table = $("#print-stations-table").DataTable({
    order: [[0, "asc"]],
    columns: [
      { data: "id", title: "Id"},
      {
        data: "name",
        title: "Name",
        render: (data, status, full) => {
          return renderLink("#", full.name, {class: "edit-print-station-link", "data-print-station-id": full.id})
        }
      },
      { data: "printStationType", title: "Print Station Type"},
      { data: "active",
        title: "Status",
        render: renderStatusLabel
      },
      { data: "printNodePrinterId", title: "PrintNode Printer Id", sortable: false},
      { data: "color", title: "Color", sortable: false}
    ],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/admin/print_stations/search",
      data: function(d) {
        d.name = filter.find("#name").val()
        d.print_station_type = filter.find("#print_station_type").val()
        d.active = filter.find("#active").val()
      }
    },
    drawCallback: function(settings) {
      $(this).find(".edit-print-station-link").click(function() {
        const printStationId = $(this).data("print-station-id")
        openSimpleFormRemoteModal(
          "/admin/print_stations/" + printStationId + "/edit",
          null,
          null,
          function(data) {
            table.ajax.reload()
          })
      })
    }
  })
  window.currentDataTable = table

  function renderStatusLabel(data) {
    if (data) {
      return $("<span/>").
        append($("<span/>", {class: "label label-success"}).text("active")).
        html()
    } else {
      return $("<span/>").
        append($("<span/>", {class: "label label-danger"}).text("disabled")).
        html()
    }
  }

  $("#new-print-station-button").click(function() {
    openSimpleFormRemoteModal(
      "/admin/print_stations/new",
      null,
      null,
      function(data) {
        table.ajax.reload()
      }
    )
  })

  filter.find("select, input").change(function(event) {
    table.ajax.reload()
  })

  $("#print-stations-table thead").addClass("table-info")
})
