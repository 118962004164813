import { Controller } from "@hotwired/stimulus"

// Reloading page menu is a round trip to avoid trouble with generating menu from a detached background job.
export default class extends Controller {
  // Either pass tab+filter or pass just the path!
  static values = { tab: String, filter: String, path: String }

  connect() {
    if (this.tabValue) {
      $.get("/page_menu", {tab: this.tabValue, filter: this.filterValue})
    } else if (this.pathValue) {
      $.get("/page_menu", {path: this.pathValue})
    } else {
      $.get("/page_menu")
    }
  }
}
