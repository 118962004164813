import React from "react"
import ReactDOM from "react-dom"
import {initInPlaceEditor, reloadPanel} from "src/shared/utils"
import {openSimpleFormRemoteModal} from "src/shared/remote_modals"
import {InPlaceEditor} from "components/utils/InPlaceEditor"
import { registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("customers-show", () => {
  $("#status").change(onCustomerStatusChange)
  $("#status").change()

  const activeTabId = $("#customer-tabs").data("active-tab")
  $(`li a[href="#${activeTabId}"]`).tab("show")

  // initializing loaded content on style tab
  $("#style").on("NW:ContentLoaded", onStyleTabLoaded)
  // initializing loaded content on remote panel
  $("#info").on("NW:ContentLoaded", onSpecialPurchasesPanelLoaded)
})

const onStyleTabLoaded = function() {
  const nextShipmentPreferencesNode = $("#next-shipment-preferences")
  ReactDOM.render(
    <InPlaceEditor
      controlType="textarea"
      property="next_shipment_preferences"
      recordType="customer"
      updateURL={nextShipmentPreferencesNode.data("url")}
      value={nextShipmentPreferencesNode.data("value")}
      readOnly={nextShipmentPreferencesNode.data("readonly")}
    />,
    nextShipmentPreferencesNode.get(0)
  )
}

const onCustomerStatusChange = function() {
  //trick to prevent situations with wrong reason_code_id
  $("#reason_code_id").prop("disabled", true)
  $("#change-status").prop("disabled", true)

  const status = $(this).val()
  const statuses = ["cancelled", "inactive", "potential_fraud", "pending_cancellation"]
  const showReasonCode = statuses.includes(status)

  $("#reason-code-form-group").toggle(showReasonCode)
  $("#reason_code_id").prop("required", showReasonCode)
  $("#reason").prop("required", !showReasonCode)
  $("#reason_code_id").val("")
  if (showReasonCode) {
    $.get("/customer_status_change_reason_codes", {status: status}, function(data, httpStatus, xhr) {
      let options = `<option value=""></option>`
      data.forEach(function(item) {
        if (item.satisfied == false) {
          item.reason = "Not Satisfied: " + item.reason
          item.class = "text-danger"
        } else if (item.satisfied == true) {
          item.reason = "Satisfied: " + item.reason
          item.class = "text-success"
        } else {
          item.class = ""
        }
        options += `<option class = "${item.class}" value = "${item.id}"> ${item.reason} </option>`
      })
      $("#reason_code_id").html(options)
    })
  }
  $("#reason_code_id").prop("disabled", false)
  $("#change-status").prop("disabled", false)
}

const onSpecialPurchasesPanelLoaded = function () {
  $("button.add_special_purchase_usage").click(function() {
    const id = $(this).data("special-purchase-id")
    openSimpleFormRemoteModal("/special_purchases/" + id + "/usages/new",
      null,
      null,
      function() {
        reloadPanel($("#special_purchases_panel_content"))
      }
    )
  })

  $("a.destroy_special_purchase_usage").on("ajax:success", function() {
    reloadPanel($("#special_purchases_panel_content"))
  })

  initInPlaceEditor(".in-place-editor-node")
}
