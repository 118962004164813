import { renderLink } from "../shared/utils"
import { formatTimestamp } from "../../utils/formatters"
import { formatMoney, registerOnReadyHandler } from "@nadinewest/common"

registerOnReadyHandler("one_way_shipments-index", () => {

  window.currentDataTable = $("#one_way_shipments_table").DataTable({
    order: [[1, "desc"]],
    columns: [
      {
        data: "id",
        title: "ID",
        sortable: true
      },
      {
        data: "created_at",
        title: "Created At",
        searchable: false,
        render: formatTimestamp
      },
      {
        data: "name",
        title: "Name",
        searchable: false,
        render: (data, type, row) =>
          renderLink(
            `/one_way_shipments/${row.id}`,
            row.name
          )
      },
      {
        data: "address",
        title: "Address",
        searchable: false,
        render: (data, type, row) =>{
          return `${data.street}, ${data.city}, ${data.state.name}, ${data.zip} `
        }
      },
      {
        data: "weight",
        title: "Weight"
      },
      {
        data: "cost",
        title: "Cost",
        render: formatMoney
      }
    ],
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/one_way_shipments/search"
    }
  })

  $("#one_way_shipments_table thead").addClass("table-info")
})
