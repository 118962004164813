/* global Product */

import {openSimpleFormRemoteModal} from "src/shared/remote_modals"

/**
 * Checks if specified categoryId supports size
 * @param categoryId category Id
 * @returns {if size is required}
 */
export function sizeRequired(categoryId) {
  categoryId = parseInt(categoryId)
  return Product.CATEGORIES_WITH_SIZE.includes(categoryId)
}

/**
 * Used to open new Product dialog
 * @param vendorId if specified then vendorId will be fixed in form
 * @param afterLoadCallback
 * @param afterCreateCallback
 * @param afterStyleFilledCallback
 */
export function openNewProductDialog(vendorId, afterLoadCallback, afterCreateCallback, afterStyleFilledCallback) {
  const _afterLoadCallback = function() {
    this.find("#product_category_id").change(function(e) {
      const categoryId = $(this).val()
      if (sizeRequired(categoryId)) {
        $("#sizes-form-group").show()
      } else {
        $("#sizes-form-group").hide()
      }
    })

    $("#product_brand_id").select2({
      tags: true,
      dropdownParent: $(".new_product"),
      tokenSeparators: [","],
      width: "100%",
    })

    if (afterLoadCallback !== null) {
      afterLoadCallback.call(this)
    }
  }

  const _okCallback = function() {
    const count = $('#sizes-form-group input[name="sizes[]"]:checked').length
    if (sizeRequired($("#product_category_id").val())) {
      if (count == 0) {
        alert("Select at least one size")
      }
      return count > 0
    } else {
      return true
    }
  }

  let url = "/products/new"
  if (vendorId != null) {
    url += "?vendor_id=" + vendorId
  }

  // opens 2nd modal, setting attributes on newly created product
  const _afterSuccessCallback = function(data, status, xhr) {
    if (afterCreateCallback !== null) {
      afterCreateCallback.call(this, data, status, xhr)
    }

    // After product is created Attributes need to be set
    const path = `/products/${data.id}/tab_style?for_modal=true`
    // When the afterCreateCallback is called it will not provide any data!
    openSimpleFormRemoteModal(path, afterCreateCallback, null, afterStyleFilledCallback)
    return false // to prevent modal from closing
  }

  // opens 1st modal, basic data, product is created
  openSimpleFormRemoteModal(url, _afterLoadCallback, _okCallback, _afterSuccessCallback)
}
