/* global Turbo */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  loadOptions(event) {
    const url = event.target.dataset.url + event.target.value

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }).then((response) => response.text()).then((html) => Turbo.renderStreamMessage(html))
  }
}
