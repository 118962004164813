import React from "react"
import PropTypes from "prop-types"

// renders customer name as link + shipping address
export function NameLinkAndAddress(props) {
  const stateName = props.address.state ? props.address.state.name : null
  return <div>
    <a href={`/customers/${props.customerId}`}>
      {props.firstName} {props.lastName}
    </a>
    <br/>
    <small>
      {props.address.street}, {props.address.city}
      <br/>
      {stateName}, {props.address.zip}
    </small>
  </div>
}

NameLinkAndAddress.propTypes = {
  customerId: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address: PropTypes.object
}
