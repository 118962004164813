import { Controller } from "@hotwired/stimulus"

// To update inventory movement based on current from location, to location & quantity input
export default class extends Controller {
  static targets = [
    "fromLocationSelect",
    "toLocationSelect",
    "quantityInput",
    "button"
  ]

  initialize () {
    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    const { target } = event

    if (target === this.fromLocationSelectTarget) {
      const { value, selectedIndex } = target

      this.resetToLocationsSelect()

      const optionIndex = this.findOptionIndex(value)
      this.removeOptionFromToLocationSelect(optionIndex)

      const quantity = target[selectedIndex].getAttribute("quantity")
      this.resetQuantityInput(quantity)
    }

    // TODO: this is not working well on Microsoft windows (theory)
    // this.handleFormValidation()
  }

  handleFormValidation () {
    if (this.isValid()) {
      this.buttonTarget.hidden = false
    } else {
      this.buttonTarget.hidden = true
    }
  }

  isValid () {
    return this.fromLocationSelectTarget.selectedIndex !== 0 &&
      this.toLocationSelectTarget.selectedIndex !== 0 &&
      this.quantityInputTarget.value &&
      this.quantityInputTarget.value > 0
  }

  resetQuantityInput (value) {
    if (value) {
      this.quantityInputTarget.setAttribute("max", value)
    }
  }

  resetToLocationsSelect () {
    if (this.currentToLocationOption && this.currentToLocationIndex) {
      this.toLocationSelectTarget.add(this.currentToLocationOption, this.currentToLocationIndex)
    }
  }

  removeOptionFromToLocationSelect (index) {
    if (index) {
      this.currentToLocationOption = this.toLocationSelectTarget.options[index]
      this.currentToLocationIndex = index
      this.toLocationSelectTarget.remove(this.currentToLocationIndex)
    }
  }

  findOptionIndex (value) {
    for(let i = 0; i < this.toLocationSelectTarget.options.length; i++) {
      if (this.toLocationSelectTarget.options[i].value === value) {
        return i
      }
    }
  }
}
