import React from "react"
import PropTypes from "prop-types"

/**
 * renders product's SKU and category name below it
 * if productId is passed in props, SKU will be rendered as a clickable link
 */
export function SkuWithCategory(props) {
  const sku = props.productId == null ?
    props.sku :
    <a href={`/products/${props.productId}${props.tab != null ? `?tab=${props.tab}` : ""}`}>
      {props.sku}
    </a>
  return <div>
    {sku}
    <div className="text-muted">{props.categoryName}</div>
  </div>
}

SkuWithCategory.propTypes = {
  productId: PropTypes.number,
  sku: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  tab: PropTypes.string
}
