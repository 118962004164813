import React from "react"
import ReactDOM from "react-dom"
import { formatTimestampAsDate } from "../../utils/formatters"
import { formatMoney, registerOnReadyHandler } from "@nadinewest/common"
import { IconsSet } from "../shared/IconsSet"
import { NameLinkAndAddress } from "./components/NameLinkAndAddress"
import { CreateShipmentButton } from "./components/CreateShipmentButton"

registerOnReadyHandler("customers-index", () => {
  const COLUMN_NAMES = {
    name: "Name",
    email: "Email",
    created_at: "Signup Date",
    top_size:  "Top Size",
    bottom_size: "Bottom Size",
    customer_status: "Status",
    avg_revenue: "Avg Revenue",
    closed_shipments_count: "Closed Shipments Count",
    last_shipped_at: "Last Ship Date"
  }

  const customersTable = $("#customers-table")
  const colJSON = customersTable.data("cols")

  const columns = $.map(colJSON, function(col) {
    const result = {data: col, title: COLUMN_NAMES[col]}
    if (col == "icons") {
      result.width = "8em"
      result.sortable = false
      result.createdCell = function(cell, cellData, rowData) {
        if (rowData.icons) {
          ReactDOM.render(
            <IconsSet
              icons={rowData.icons}
            />,
            cell
          )
        }
      }
    } else if (col == "created_at") {
      result.render = formatTimestampAsDate
    } else if (col == "name") {
      result.data = "id"
      result.createdCell = function(cell, cellData, rowData) {
        ReactDOM.render(
          <NameLinkAndAddress
            customerId={rowData.id}
            firstName={rowData.first_name}
            lastName={rowData.last_name}
            address={rowData.shipping_address}
          />,
          cell
        )
      }
    } else if (col == "last_shipped_at") {
      result.render = formatTimestampAsDate
      result.sortable = false
    } else if (col == "closed_shipments_count") {
      result.sortable = false
    } else if (col == "avg_revenue") {
      result.sortable = false
      result.render = formatMoney
    } else if (col == "create_shipment") {
      result.sortable = false
      result.data = "id"
      result.createdCell = function(cell, cellData, rowData) {
        ReactDOM.render(
          <CreateShipmentButton
            customerId={rowData.id}
          />,
          cell
        )
      }
    }
    return result
  })

  const setFilters = function(d) {
    $.each($("form#customers_filter").serializeArray(), function(index, val) {
      const param = val.name
      const value = val.value
      if (value === null || value === "") {
        return
      }
      if (param == "top_sizes[]" || param == "bottom_sizes[]" || param == "flags[]") {
        // all checkboxes with shared name
        let values = d[param]
        if (values == null) {
          values = [value]
          d[param] = values
        } else {
          values.push(value)
        }
      } else {
        d[param] = val.value
      }
    })
  }

  const table = customersTable.DataTable({
    order: [[customersTable.data("order-col-index"), "desc"]],
    columns: columns,
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: customersTable.data("search-customers-path"),
      data: function(d) {
        setFilters(d)
      }
    }
  }).on("xhr.dt", function ( e, settings, json, xhr ) {
    $("#customers_filter_panel_heading .card-title a").html("Current filter: " + json.filterString)
  })

  $("form#customers_filter").find("input, select").change(function() {
    table.ajax.reload()
  })

  $("#clear_customers_filter").click(function() {
    const form = $("form#customers_filter")
    form.find("input[type='text'], select").val("")
    form.find("#charge_failed_counter").val("-1")
    form.find("input[type='checkbox'][name='top_sizes[]']").prop("checked", true)
    form.find("input[type='checkbox'][name='bottom_sizes[]']").prop("checked", true)
    form.find("input[type='checkbox'][name='flags[]']").prop("checked", false)
    table.ajax.reload()
  })

  window.currentDataTable = table

  $("#customers-table thead").addClass("table-info")
})
