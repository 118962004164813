// Custom global functions
// Put stuff here, when a custom stimulus controller is just too much.

window.printInventoryReplenishment = function() {
  const urlWithParams = new URL(
    "/inventory_replenishment/print",
    window.location.href
  )
  $.each($("#replenishment-filter-form").serializeArray(), function (index, val) {
    const param = val.name
    const value = val.value
    if (value !== null && value !== "") {
      urlWithParams.searchParams.append(param, value)
    }
  })
  window.open(urlWithParams, "_blank")
}
