import { renderLink } from "../../shared/utils"
import { openSimpleFormRemoteModal } from "../../shared/remote_modals"
import { registerOnReadyHandler } from "@nadinewest/common"
import { formatTimestamp } from "../../../utils/formatters"

registerOnReadyHandler("admin-users-index", () => {

  const setFilters = function(d) {
    $.each($("form#roles_filter").serializeArray(), function(index, val) {
      const param = val.name
      const value = val.value
      if (value === null || value === "") {
        return
      }
      if (param == "roles[]") {
        // all checkboxes with shared name
        let values = d[param]
        if (values == null) {
          values = [value]
          d[param] = values
        } else {
          values.push(value)
        }
      } else {
        d[param] = val.value
      }
    })
  }

  const table = $("#users_table").DataTable({
    order: [[1, "asc"]],
    columns: [
      { data: "current_warehouse_name" },
      { data: "login",
        render: (data, type, row) => {
          return renderLink("#", row.login, {class: "edit-user-link", "data-user-id": row.id})
        }
      },
      { data: "name" },
      {
        data: "locked_at", sortable: false, searchable: false,
        render: renderUserStatus
      },
      { data: "email" },
      { data: "roles", sortable: false},
      { data: "print_station_name"},
      { data: "locale", sortable: false, searchable: false }
    ],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: "/admin/users/search",
      data: function(d) {
        setFilters(d)
        const filter = $("#users_filter_panel_content_body")
        d.active = filter.find("#active").prop("checked")
        d.name = filter.find("#name").val()
        d.current_warehouse_id = filter.find("#current_warehouse_id").val()
      }
    },
    drawCallback: function(settings) {
      $(this).find(".edit-user-link").click(function() {
        const userId = $(this).data("user-id")
        openSimpleFormRemoteModal(
          "/admin/users/" + userId + "/edit",
          null,
          null,
          function(data) {
            table.ajax.reload()
          })
      })
    }
  })
  window.currentDataTable = table

  $("#new_user_button").click(function() {
    openSimpleFormRemoteModal(
      "/admin/users/new",
      null,
      null,
      function(data) {
        table.ajax.reload()
      }
    )
  })

  $("#users_filter_panel_content_body").find("select, input").change(function(event) {
    $("#users_table").DataTable().ajax.reload()
  })

  function renderUserStatus(data) {
    if (data) {
      return $("<span/>").append($("<span/>", {class: "label label-danger"}).text("disabled")).
        append($("<div/>").text(formatTimestamp(data))).html()
    } else {
      return $("<span/>").append($("<span/>", {class: "label label-success"}).text("active")).html()
    }
  }
})
