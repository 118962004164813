import { formatTimestampAsDate } from "utils/formatters"
import { openNewProductDialog } from "./new"
import { formatMoney, registerOnReadyHandler } from "@nadinewest/common"
import { adjustColour } from "../../utils/common"
import { renderProductImage, renderSkuWithCategory } from "../shared/utils"

registerOnReadyHandler("products-index", () => {
  const productsTable = $("#products-table")
  const filter = productsTable.data("filter")

  $("#new_product_button").click(function() {
    openNewProductDialog(
      null,
      function() {
        /* Preselecting category according to selected tab */
        $("#product_category_id").val(productsTable.data("current-product-category"))
      },
      function() {
        // Clicking the table cell selects the inner checkbox
        $(".attribute-checkbox-cell").click(function(e) {
          const checkbox = $(this).find("input:checkbox").get(0)
          if (e.target != checkbox) {
            checkbox.checked = !checkbox.checked
            checkbox.dispatchEvent(new Event("change"))
          }
        })

        $(".attribute-checkbox-title.js-toggle-contrasting-color").each(function(index) {
          const attribute = $(this).data("attribute")
          adjustColour(attribute, $(this))
        })
      },
      function(data) {
        /* redirects to new product's page after style info filled */
        window.location.href = "/products/" + data.id
      }
    )
  })

  const showVendorLink = productsTable.data("is-show-vendor")
  const showBrandLink = productsTable.data("is-show-brand")

  const table = productsTable.DataTable({
    order: [[1, "desc"]],
    columns: [
      {
        data: "image_url",
        title: "Image",
        sortable: false,
        searchable: false,
        className: "item-image"
      },
      {
        data: "created_at",
        title: "Date Created",
        searchable: false,
        render: formatTimestampAsDate
      },
      {
        data: "sku",
        title: "Style Number",
        class: "item-sku"
      },
      {
        data: "subcategory",
        title: "Subcategory"
      },
      {
        data: "name",
        title: "Name",
      },
      {
        data: "vendor",
        title: "Vendor",
        defaultContent: "",
        render: (data) => renderNameWithLink(data, "vendors", showVendorLink),
      },
      {
        data: "vendor_sku",
        title: "Vendor SKU"
      },
      {
        data: "brand",
        title: "Brand",
        defaultContent: "",
        render: (data) => renderNameWithLink(data, "brands", showBrandLink),
      },
      {
        data: "suggested_price",
        title: "Price",
        render: formatMoney,
      },
      {
        data: "flavors_count",
        title: "Flavors Count",
        sortable: false
      },
      {
        data: "variations_count",
        title: "Variations Count",
        sortable: false
      }
    ],
    filter: false,
    serverSide: true,
    serverMethod: "post",
    ajax: {
      url: productsTable.data("search-products-path"),
      data: function (d) {
        $.each($("form#products_filter").serializeArray(), function (index, val) {
          const param = val.name
          const value = val.value
          if (value === null || value === "") {
            return
          }
          if (param == "sizes[]") {
            // all checkboxes with shared name
            let values = d[param]
            if (values == null) {
              values = [value]
              d[param] = values
            } else {
              values.push(value)
            }
          } else {
            d[param] = val.value
          }
        })
      }
    },
    rowCallback(row, data) {
      const itemImageCell = $(row).find(".item-image").get(0)
      const itemSkuCell = $(row).find(".item-sku").get(0)
      const tabValue = filter === "style_data_missing" ? "style" : null

      if (data.image_url != null) {
        const imageHtml = renderProductImage(data.image_url, "small", "Product Image")
        $(itemImageCell).html(imageHtml)
      }

      const skuHtml = renderSkuWithCategory(data.sku, data.category.name, data.id, tabValue)
      $(itemSkuCell).html(skuHtml)
    }
  }).on("xhr.dt", function (e, settings, json, xhr) {
    $("#products_filter_panel_heading .card-title a").html("Current filter: " + json.filterString)
  })
  window.currentDataTable = table

  $("form#products_filter").find("input, select").change(function () {
    table.ajax.reload()
  })

  $("#clear_products_filter").click(function () {
    const form = $("form#products_filter")
    form.find("input[type='text'], select").val("")
    form.find("input[type='checkbox']").prop("checked", true)
    table.ajax.reload()
  })

  $("#products-table thead").addClass("table-info")

  $(".select2-brand_id").select2({
    tokenSeparators: [","],
    width: "100%",
  })

  function renderNameWithLink(data, path, showLink) {
    if (!data) {
      return '<div class="text-danger">Unknown</div>'
    } else if (!showLink) {
      return `<div>${data.name}</div>`
    } else {
      return `<div><a href="/${path}/${data.id}">${data.name}</a></div>`
    }
  }
})
