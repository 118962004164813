import { Controller } from "@hotwired/stimulus"

// Bootstrap nav/tabs helper
// Shows active tab on connect
export default class extends Controller {
  connect() {
    const tabs = $(this.element)
    const activeTab = tabs.data("active-tab")
    $('li a[href="#' + activeTab + '"]').tab("show")
  }
}
