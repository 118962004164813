import PropTypes from "prop-types"
import React from "react"

export function ProductImage(props) {
  return <div className={`product-image product-image-${props.size}`}>
    <img src={props.imageURL} alt="not loaded" title={props.title}/>
  </div>
}

ProductImage.propTypes = {
  imageURL: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["tiny", "small", "medium", "square"]),
  title: PropTypes.string
}

ProductImage.defaultProps = {
  size: "small",
  title: ""
}
