import { Controller } from "@hotwired/stimulus"
import { selectedValues, encodeQueryData } from "src/shared/utils"

export default class extends Controller {
  downloadCsv(e) {
    e.preventDefault()

    const formData = {}
    const filter = $("#orders_filter_panel_content_body")
    formData.vendor_id = filter.find("#vendor_id").val()
    formData["statuses[]"] = selectedValues(filter.find("input[name='status']:checked"))
    formData.created_at_from = filter.find("#created_at_from").val()
    formData.created_at_to = filter.find("#created_at_to").val()
    formData.expected_arrival_date_from = filter.find("#expected_arrival_date_from").val()
    formData.expected_arrival_date_to = filter.find("#expected_arrival_date_to").val()
    formData.vendor_order_id = filter.find("#vendor_order_id").val()
    formData["types[]"] = selectedValues(filter.find("input[name='type']:checked"))

    window.location = "/orders.csv?" + encodeQueryData(formData)
  }
}
