import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

export function loadSentry() {
  let env = "development"
  if (window.hasOwnProperty("sentryEnv")) {
    env = window.sentryEnv
  }
  Sentry.init({
    dsn: "https://ea8b6ad07df241639f9843b0892b3c9a@o380337.ingest.sentry.io/5206024",
    environment: env,
    // To set a release version
    // release: "my-project-name@" + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
  })

  let user = "default"
  if (window.hasOwnProperty("currentUser")) {
    user = window.currentUser
  }
  Sentry.setUser({ username: user })
}
